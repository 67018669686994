/* eslint-disable */
/**
 * 
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthenticateOTPParams,
  ChangePasswordRequest,
  CountusersParams,
  DeviceIdRequest,
  EmailSetting,
  GetAllSessionsParams,
  GetAllusersParams,
  JwtResponse,
  KeyAndPasswordVM,
  LoginVM,
  LogoutRequest,
  PageSessionSummary,
  Problem,
  RefreshTokenRequest,
  RemoveADAccountParams,
  SendOTPParams,
  UpdateUserProfile,
  UserDTO,
  UserProfile
} from './auth.schemas'
import { useCustomInstance } from './use-custom-instance';
import { customFormData } from './custom-form-data';



export const useGetuserHook = () => {
        const getuser = useCustomInstance<UserDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getuser(
          {url: `/auth/api/users/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetuserQueryKey = (id: number,) => {
    
    return [`/auth/api/users/${id}`] as const;
    }

    
export const useGetuserQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>, TError = Problem>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetuserQueryKey(id);

  const getuser =  useGetuserHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>> = ({ signal }) => getuser(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetuserQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>>
export type GetuserQueryError = Problem

export const useGetuser = <TData = Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>, TError = Problem>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetuserHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetuserQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useUpdateuserHook = () => {
        const updateuser = useCustomInstance<UserDTO>();

        return (
    id: number,
    userDTO: UserDTO,
 ) => {
        return updateuser(
          {url: `/auth/api/users/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userDTO
    },
          );
        }
      }
    


export const useUpdateuserMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateuser =  useUpdateuserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateuserHook>>>, {id: number;data: UserDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateuser(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateuserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateuserHook>>>>
    export type UpdateuserMutationBody = UserDTO
    export type UpdateuserMutationError = Problem

    export const useUpdateuser = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateuserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useDeleteuserHook = () => {
        const deleteuser = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteuser(
          {url: `/auth/api/users/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteuserMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteuserHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteuserHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteuser =  useDeleteuserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteuserHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteuser(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteuserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteuserHook>>>>
    
    export type DeleteuserMutationError = Problem

    export const useDeleteuser = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteuserHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteuserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const usePartialUpdateuserHook = () => {
        const partialUpdateuser = useCustomInstance<UserDTO>();

        return (
    id: number,
    userDTO: UserDTO,
 ) => {
        return partialUpdateuser(
          {url: `/auth/api/users/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: userDTO
    },
          );
        }
      }
    


export const usePartialUpdateuserMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateuser =  usePartialUpdateuserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateuserHook>>>, {id: number;data: UserDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateuser(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateuserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateuserHook>>>>
    export type PartialUpdateuserMutationBody = UserDTO
    export type PartialUpdateuserMutationError = Problem

    export const usePartialUpdateuser = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateuserHook>>>, TError,{id: number;data: UserDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateuserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useSyncMailConfigHook = () => {
        const syncMailConfig = useCustomInstance<void>();

        return (
    emailSetting: EmailSetting,
 ) => {
        return syncMailConfig(
          {url: `/auth/api/syncMailConfig`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: emailSetting
    },
          );
        }
      }
    


export const useSyncMailConfigMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const syncMailConfig =  useSyncMailConfigHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, {data: EmailSetting}> = (props) => {
          const {data} = props ?? {};

          return  syncMailConfig(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SyncMailConfigMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>>
    export type SyncMailConfigMutationBody = EmailSetting
    export type SyncMailConfigMutationError = Problem

    export const useSyncMailConfig = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext>, }
) => {

      const mutationOptions = useSyncMailConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useGetAllusersHook = () => {
        const getAllusers = useCustomInstance<UserDTO[]>();

        return (
    params?: GetAllusersParams,
 signal?: AbortSignal
) => {
        return getAllusers(
          {url: `/auth/api/users`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllusersQueryKey = (params?: GetAllusersParams,) => {
    
    return [`/auth/api/users`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllusersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>, TError = Problem>(params?: GetAllusersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllusersQueryKey(params);

  const getAllusers =  useGetAllusersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>> = ({ signal }) => getAllusers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllusersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>>
export type GetAllusersQueryError = Problem

export const useGetAllusers = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>, TError = Problem>(
 params?: GetAllusersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllusersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllusersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useCreateuserHook = () => {
        const createuser = useCustomInstance<UserDTO>();

        return (
    userDTO: UserDTO,
 ) => {
        return createuser(
          {url: `/auth/api/users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userDTO
    },
          );
        }
      }
    


export const useCreateuserMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateuserHook>>>, TError,{data: UserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateuserHook>>>, TError,{data: UserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createuser =  useCreateuserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateuserHook>>>, {data: UserDTO}> = (props) => {
          const {data} = props ?? {};

          return  createuser(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateuserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateuserHook>>>>
    export type CreateuserMutationBody = UserDTO
    export type CreateuserMutationError = Problem

    export const useCreateuser = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateuserHook>>>, TError,{data: UserDTO}, TContext>, }
) => {

      const mutationOptions = useCreateuserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useCreateTokenHook = () => {
        const createToken = useCustomInstance<void>();

        return (
    deviceIdRequest: DeviceIdRequest,
 ) => {
        return createToken(
          {url: `/auth/api/sessions/device-token`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deviceIdRequest
    },
          );
        }
      }
    


export const useCreateTokenMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTokenHook>>>, TError,{data: DeviceIdRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTokenHook>>>, TError,{data: DeviceIdRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createToken =  useCreateTokenHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateTokenHook>>>, {data: DeviceIdRequest}> = (props) => {
          const {data} = props ?? {};

          return  createToken(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTokenMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateTokenHook>>>>
    export type CreateTokenMutationBody = DeviceIdRequest
    export type CreateTokenMutationError = Problem

    export const useCreateToken = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTokenHook>>>, TError,{data: DeviceIdRequest}, TContext>, }
) => {

      const mutationOptions = useCreateTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useRequestPasswordResetHook = () => {
        const requestPasswordReset = useCustomInstance<void>();

        return (
    requestPasswordResetBody: string,
 ) => {
        return requestPasswordReset(
          {url: `/auth/api/reset-password/init`, method: 'post',
      headers: {'Content-Type': 'text/plain', },
      data: requestPasswordResetBody
    },
          );
        }
      }
    


export const useRequestPasswordResetMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRequestPasswordResetHook>>>, TError,{data: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRequestPasswordResetHook>>>, TError,{data: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const requestPasswordReset =  useRequestPasswordResetHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRequestPasswordResetHook>>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  requestPasswordReset(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RequestPasswordResetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRequestPasswordResetHook>>>>
    export type RequestPasswordResetMutationBody = string
    export type RequestPasswordResetMutationError = Problem

    export const useRequestPasswordReset = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRequestPasswordResetHook>>>, TError,{data: string}, TContext>, }
) => {

      const mutationOptions = useRequestPasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Reset password complete require recaptcha with header 'captcha-response'
 * @summary Reset password
 */
export const useCompletePasswordResetHook = () => {
        const completePasswordReset = useCustomInstance<void>();

        return (
    keyAndPasswordVM: KeyAndPasswordVM,
 ) => {
        return completePasswordReset(
          {url: `/auth/api/reset-password/complete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: keyAndPasswordVM
    },
          );
        }
      }
    


export const useCompletePasswordResetMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCompletePasswordResetHook>>>, TError,{data: KeyAndPasswordVM}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCompletePasswordResetHook>>>, TError,{data: KeyAndPasswordVM}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const completePasswordReset =  useCompletePasswordResetHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCompletePasswordResetHook>>>, {data: KeyAndPasswordVM}> = (props) => {
          const {data} = props ?? {};

          return  completePasswordReset(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CompletePasswordResetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCompletePasswordResetHook>>>>
    export type CompletePasswordResetMutationBody = KeyAndPasswordVM
    export type CompletePasswordResetMutationError = Problem

    /**
 * @summary Reset password
 */
export const useCompletePasswordReset = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCompletePasswordResetHook>>>, TError,{data: KeyAndPasswordVM}, TContext>, }
) => {

      const mutationOptions = useCompletePasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useRemoveADAccountHook = () => {
        const removeADAccount = useCustomInstance<void>();

        return (
    params: RemoveADAccountParams,
 ) => {
        return removeADAccount(
          {url: `/auth/api/removeLDAP`, method: 'post',
        params
    },
          );
        }
      }
    


export const useRemoveADAccountMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveADAccountHook>>>, TError,{params: RemoveADAccountParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveADAccountHook>>>, TError,{params: RemoveADAccountParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const removeADAccount =  useRemoveADAccountHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveADAccountHook>>>, {params: RemoveADAccountParams}> = (props) => {
          const {params} = props ?? {};

          return  removeADAccount(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveADAccountMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveADAccountHook>>>>
    
    export type RemoveADAccountMutationError = Problem

    export const useRemoveADAccount = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveADAccountHook>>>, TError,{params: RemoveADAccountParams}, TContext>, }
) => {

      const mutationOptions = useRemoveADAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useRefreshTokenHook = () => {
        const refreshToken = useCustomInstance<JwtResponse>();

        return (
    refreshTokenRequest: RefreshTokenRequest,
 ) => {
        return refreshToken(
          {url: `/auth/api/refresh-token`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: refreshTokenRequest
    },
          );
        }
      }
    


export const useRefreshTokenMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError,{data: RefreshTokenRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError,{data: RefreshTokenRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const refreshToken =  useRefreshTokenHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, {data: RefreshTokenRequest}> = (props) => {
          const {data} = props ?? {};

          return  refreshToken(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RefreshTokenMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>>
    export type RefreshTokenMutationBody = RefreshTokenRequest
    export type RefreshTokenMutationError = Problem

    export const useRefreshToken = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRefreshTokenHook>>>, TError,{data: RefreshTokenRequest}, TContext>, }
) => {

      const mutationOptions = useRefreshTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useUpdateProfileHook = () => {
        const updateProfile = useCustomInstance<UserProfile>();

        return (
    updateUserProfile: UpdateUserProfile,
 ) => {
        return updateProfile(
          {url: `/auth/api/profile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateUserProfile
    },
          );
        }
      }
    


export const useUpdateProfileMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProfileHook>>>, TError,{data: UpdateUserProfile}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProfileHook>>>, TError,{data: UpdateUserProfile}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateProfile =  useUpdateProfileHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateProfileHook>>>, {data: UpdateUserProfile}> = (props) => {
          const {data} = props ?? {};

          return  updateProfile(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateProfileHook>>>>
    export type UpdateProfileMutationBody = UpdateUserProfile
    export type UpdateProfileMutationError = Problem

    export const useUpdateProfile = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProfileHook>>>, TError,{data: UpdateUserProfile}, TContext>, }
) => {

      const mutationOptions = useUpdateProfileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useLogoutHook = () => {
        const logout = useCustomInstance<void>();

        return (
    logoutRequest: LogoutRequest,
 ) => {
        return logout(
          {url: `/auth/api/logout`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: logoutRequest
    },
          );
        }
      }
    


export const useLogoutMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: LogoutRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: LogoutRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const logout =  useLogoutHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, {data: LogoutRequest}> = (props) => {
          const {data} = props ?? {};

          return  logout(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LogoutMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>>
    export type LogoutMutationBody = LogoutRequest
    export type LogoutMutationError = Problem

    export const useLogout = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: LogoutRequest}, TContext>, }
) => {

      const mutationOptions = useLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useChangePasswordHook = () => {
        const changePassword = useCustomInstance<void>();

        return (
    changePasswordRequest: ChangePasswordRequest,
 ) => {
        return changePassword(
          {url: `/auth/api/change-password`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordRequest
    },
          );
        }
      }
    


export const useChangePasswordMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError,{data: ChangePasswordRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError,{data: ChangePasswordRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const changePassword =  useChangePasswordHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, {data: ChangePasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  changePassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>>
    export type ChangePasswordMutationBody = ChangePasswordRequest
    export type ChangePasswordMutationError = Problem

    export const useChangePassword = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError,{data: ChangePasswordRequest}, TContext>, }
) => {

      const mutationOptions = useChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useBindLDAPHook = () => {
        const bindLDAP = useCustomInstance<boolean>();

        return (
    loginVM: LoginVM,
 ) => {
        return bindLDAP(
          {url: `/auth/api/bindLDAP`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginVM
    },
          );
        }
      }
    


export const useBindLDAPMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBindLDAPHook>>>, TError,{data: LoginVM}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBindLDAPHook>>>, TError,{data: LoginVM}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const bindLDAP =  useBindLDAPHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBindLDAPHook>>>, {data: LoginVM}> = (props) => {
          const {data} = props ?? {};

          return  bindLDAP(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BindLDAPMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBindLDAPHook>>>>
    export type BindLDAPMutationBody = LoginVM
    export type BindLDAPMutationError = Problem

    export const useBindLDAP = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBindLDAPHook>>>, TError,{data: LoginVM}, TContext>, }
) => {

      const mutationOptions = useBindLDAPMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Authenticate a user with username and password, if login failed, will return 400 bad request and  if login failed count > 4 will throw error with : {
  "entityName": "RECAPTCHA",
  "errorKey": "${count-login-failed}"
}
, after that, client must call api login with header "captcha-response" eles  
 * @summary Authenticate a user
 */
export const useAuthenticateHook = () => {
        const authenticate = useCustomInstance<JwtResponse>();

        return (
    loginVM: LoginVM,
 ) => {
        return authenticate(
          {url: `/auth/api/authenticate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginVM
    },
          );
        }
      }
    


export const useAuthenticateMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateHook>>>, TError,{data: LoginVM}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateHook>>>, TError,{data: LoginVM}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const authenticate =  useAuthenticateHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAuthenticateHook>>>, {data: LoginVM}> = (props) => {
          const {data} = props ?? {};

          return  authenticate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthenticateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAuthenticateHook>>>>
    export type AuthenticateMutationBody = LoginVM
    export type AuthenticateMutationError = Problem

    /**
 * @summary Authenticate a user
 */
export const useAuthenticate = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateHook>>>, TError,{data: LoginVM}, TContext>, }
) => {

      const mutationOptions = useAuthenticateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useAuthenticateLDAPHook = () => {
        const authenticateLDAP = useCustomInstance<JwtResponse>();

        return (
    loginVM: LoginVM,
 ) => {
        return authenticateLDAP(
          {url: `/auth/api/authenticateLDAP`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginVM
    },
          );
        }
      }
    


export const useAuthenticateLDAPMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateLDAPHook>>>, TError,{data: LoginVM}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateLDAPHook>>>, TError,{data: LoginVM}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const authenticateLDAP =  useAuthenticateLDAPHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAuthenticateLDAPHook>>>, {data: LoginVM}> = (props) => {
          const {data} = props ?? {};

          return  authenticateLDAP(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthenticateLDAPMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAuthenticateLDAPHook>>>>
    export type AuthenticateLDAPMutationBody = LoginVM
    export type AuthenticateLDAPMutationError = Problem

    export const useAuthenticateLDAP = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateLDAPHook>>>, TError,{data: LoginVM}, TContext>, }
) => {

      const mutationOptions = useAuthenticateLDAPMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useAuthenticateOTPHook = () => {
        const authenticateOTP = useCustomInstance<JwtResponse>();

        return (
    params: AuthenticateOTPParams,
 ) => {
        return authenticateOTP(
          {url: `/auth/api/authenticate/verify`, method: 'post',
        params
    },
          );
        }
      }
    


export const useAuthenticateOTPMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateOTPHook>>>, TError,{params: AuthenticateOTPParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateOTPHook>>>, TError,{params: AuthenticateOTPParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const authenticateOTP =  useAuthenticateOTPHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAuthenticateOTPHook>>>, {params: AuthenticateOTPParams}> = (props) => {
          const {params} = props ?? {};

          return  authenticateOTP(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AuthenticateOTPMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAuthenticateOTPHook>>>>
    
    export type AuthenticateOTPMutationError = Problem

    export const useAuthenticateOTP = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthenticateOTPHook>>>, TError,{params: AuthenticateOTPParams}, TContext>, }
) => {

      const mutationOptions = useAuthenticateOTPMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Send OTP to user using email (EMAIL) or telegram (TELEGRAM)
 * @summary Send OTP to user
 */
export const useSendOTPHook = () => {
        const sendOTP = useCustomInstance<JwtResponse>();

        return (
    params?: SendOTPParams,
 ) => {
        return sendOTP(
          {url: `/auth/api/authenticate/send-otp`, method: 'post',
        params
    },
          );
        }
      }
    


export const useSendOTPMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendOTPHook>>>, TError,{params?: SendOTPParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendOTPHook>>>, TError,{params?: SendOTPParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const sendOTP =  useSendOTPHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendOTPHook>>>, {params?: SendOTPParams}> = (props) => {
          const {params} = props ?? {};

          return  sendOTP(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SendOTPMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendOTPHook>>>>
    
    export type SendOTPMutationError = Problem

    /**
 * @summary Send OTP to user
 */
export const useSendOTP = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendOTPHook>>>, TError,{params?: SendOTPParams}, TContext>, }
) => {

      const mutationOptions = useSendOTPMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useCountusersHook = () => {
        const countusers = useCustomInstance<number>();

        return (
    params?: CountusersParams,
 signal?: AbortSignal
) => {
        return countusers(
          {url: `/auth/api/users/count`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getCountusersQueryKey = (params?: CountusersParams,) => {
    
    return [`/auth/api/users/count`, ...(params ? [params]: [])] as const;
    }

    
export const useCountusersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>, TError = Problem>(params?: CountusersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCountusersQueryKey(params);

  const countusers =  useCountusersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>> = ({ signal }) => countusers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type CountusersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>>
export type CountusersQueryError = Problem

export const useCountusers = <TData = Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>, TError = Problem>(
 params?: CountusersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountusersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useCountusersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useGetAllSessionsHook = () => {
        const getAllSessions = useCustomInstance<PageSessionSummary>();

        return (
    params?: GetAllSessionsParams,
 signal?: AbortSignal
) => {
        return getAllSessions(
          {url: `/auth/api/sessions`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllSessionsQueryKey = (params?: GetAllSessionsParams,) => {
    
    return [`/auth/api/sessions`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllSessionsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>, TError = Problem>(params?: GetAllSessionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllSessionsQueryKey(params);

  const getAllSessions =  useGetAllSessionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>> = ({ signal }) => getAllSessions(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllSessionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>>
export type GetAllSessionsQueryError = Problem

export const useGetAllSessions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>, TError = Problem>(
 params?: GetAllSessionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSessionsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllSessionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useMeHook = () => {
        const me = useCustomInstance<UserProfile>();

        return (
    
 signal?: AbortSignal
) => {
        return me(
          {url: `/auth/api/me`, method: 'get', signal
    },
          );
        }
      }
    

export const getMeQueryKey = () => {
    
    return [`/auth/api/me`] as const;
    }

    
export const useMeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useMeHook>>>, TError = Problem>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useMeHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeQueryKey();

  const me =  useMeHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useMeHook>>>> = ({ signal }) => me(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useMeHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type MeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useMeHook>>>>
export type MeQueryError = Problem

export const useMe = <TData = Awaited<ReturnType<ReturnType<typeof useMeHook>>>, TError = Problem>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useMeHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useDeleteSessionHook = () => {
        const deleteSession = useCustomInstance<void>();

        return (
    id: string,
 ) => {
        return deleteSession(
          {url: `/auth/api/sessions/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteSessionMutationOptions = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSessionHook>>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSessionHook>>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteSession =  useDeleteSessionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSessionHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteSession(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteSessionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSessionHook>>>>
    
    export type DeleteSessionMutationError = Problem

    export const useDeleteSession = <TError = Problem,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSessionHook>>>, TError,{id: string}, TContext>, }
) => {

      const mutationOptions = useDeleteSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
