import Menu from "admin-cms/components/layout/Menu";
import TopBar from "admin-cms/components/layout/TopBar";
import { selectCmsSettings } from "admin-cms/redux/adminCmsSettingsSlice";
import { Layout } from "antd";
import classNames from "classnames";
import Footer from "components/cleanui/layout/Footer";
import IdleTimer from "components/system/IdleTimer";
import { useSelector } from "react-redux";

const AdminCMSLayout = ({ children }) => {
  const {
    isContentMaxWidth,
    isAppMaxWidth,
    isGrayBackground,
    isSquaredBorders,
    isCardShadow,
    isBorderless,
    isTopbarFixed,
    isGrayTopbar,
  } = useSelector(selectCmsSettings);
  return (
    <div
      className={classNames({ cui__layout__grayBackground: isGrayBackground })}
    >
      <IdleTimer />
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames("cui__layout__header", {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: "100%", position: "relative" }}>
            <div className="cui__utils__content">{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminCMSLayout;
