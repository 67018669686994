/* eslint-disable */
function isUndefined(value) {
  return value === undefined;
}

function isNull(value) {
  return value === null;
}

function isBoolean(value) {
  return typeof value === 'boolean';
}

function isObject(value) {
  return value === Object(value);
}

function isArray(value) {
  return Array.isArray(value);
}

function isDate(value) {
  return value instanceof Date;
}

function isBlob(value, isReactNative) {
  return isReactNative
    ? isObject(value) && !isUndefined(value.uri)
    : isObject(value) &&
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function';
}

function isFile(value, isReactNative) {
  return (
    isBlob(value, isReactNative) &&
    typeof value.name === 'string' &&
    (isObject(value.lastModifiedDate) || typeof value.lastModified === 'number')
  );
}

function initCfg(value) {
  return isUndefined(value) ? false : value;
}

function serialize(obj, config, formData, previousKey) {
  config = config || {};
  formData = formData || new FormData();

  config.indices = initCfg(config.indices);
  config.nullsAsUndefineds = initCfg(config.nullsAsUndefineds);
  config.booleansAsIntegers = initCfg(config.booleansAsIntegers);
  config.allowEmptyArrays = initCfg(config.allowEmptyArrays);
  config.noFilesWithArrayNotation = initCfg(config.noFilesWithArrayNotation);
  config.dotsForObjectNotation = initCfg(config.dotsForObjectNotation);

  const isReactNative = typeof formData.getParts === 'function';

  if (isUndefined(obj)) {
    return formData;
  }

  if (isNull(obj)) {
    if (!config.nullsAsUndefineds) {
      formData.append(previousKey, '');
    }
  } else if (isBoolean(obj)) {
    if (config.booleansAsIntegers) {
      formData.append(previousKey, obj ? 1 : 0);
    } else {
      formData.append(previousKey, obj);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        let key = previousKey;

        // if (cfg.noFilesWithArrayNotation && isFile(value, isReactNative)) {
        //   key = pre;
        // }

        serialize(value, config, formData, key);
      });
    } else if (config.allowEmptyArrays) {
      formData.append(`${previousKey}`, '');
    }
  } else if (isDate(obj)) {
    formData.append(previousKey, obj.toISOString());
  } else if (isObject(obj) && !isBlob(obj, isReactNative)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = previousKey
        ? config.dotsForObjectNotation
          ? `${previousKey}.${prop}`
          : `${previousKey}[${prop}]`
        : prop;

      serialize(value, config, formData, key);
    });
  } else {
    formData.append(previousKey, obj);
  }

  return formData;
}

export const customFormData = <Body>(body: Body): FormData => {
  const formData = serialize(body, {
    nullsAsUndefineds: true,
    noFilesWithArrayNotation: true,
  }, null, null);
  return formData;
};

export default customFormData;

