/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Dropdown, Row } from "antd";
import Notification from "components/notification/Notification";
import { useCountMyNotifications } from "@api/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "index";
import styles from "./style.module.scss";

const Actions = () => {
  const [refreshCount, setRefreshCount] = useState(Math.random());

  const {
    refetch: refetchCountMyNotifications,
    data: numberOfUnreadNotifications,
  } = useCountMyNotifications();
  const [open, setOpen] = useState(false);
  const [openApps, setOpenApps] = useState(false);

  useEffect(() => {
    refetchCountMyNotifications();
  }, [refetchCountMyNotifications, refreshCount]);

  const menu = (
    <div className="card cui__utils__shadow width-350 border-0">
      <div className="card-body p-0">
        <Notification
          numberOfUnreadNotifications={numberOfUnreadNotifications}
          setRefreshCount={setRefreshCount}
          setOpen={setOpen}
        />
      </div>
    </div>
  );

  const apps = (
    <div className="card cui__utils__shadow width-300 border-0">
      <div className="card-body p-4" style={{ padding: "2rem" }}>
        <Row style={{ width: "100%" }} justify="space-around">
          <Col>
            <Card
              className={styles.card_app}
              bodyStyle={{ padding: "10px" }}
              onClick={() => {
                history.push("/");
              }}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-file-lines"
                style={{ fontSize: "30px" }}
              />
              <div>Văn bản</div>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.card_app}
              bodyStyle={{ padding: "10px" }}
              onClick={() => {
                history.push("/cms");
              }}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-newspaper"
                style={{ fontSize: "30px" }}
              />
              <div>CMS</div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement="bottomRight"
        arrow
        destroyPopupOnHide
        overlayStyle={{ zIndex: 1009 }}
        open={open}
        onOpenChange={(e) => {
          setOpen(e);
          setRefreshCount(Math.random());
        }}
      >
        <Badge count={numberOfUnreadNotifications}>
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </Dropdown>
      <Dropdown
        overlay={apps}
        trigger={["click"]}
        placement="bottomRight"
        arrow
        destroyPopupOnHide
        overlayStyle={{ zIndex: 1009 }}
        open={openApps}
        onOpenChange={(e) => {
          setOpenApps(e);
        }}
      >
        <i
          style={{ marginLeft: "20px" }}
          className={`${styles.icon} fe fe-grid`}
        />
      </Dropdown>
    </>
  );
};

export default Actions;
