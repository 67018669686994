/* eslint-disable import/prefer-default-export */
export const cmsMenu = [
  {
    title: "Quản lý tin bài",
    key: "post-management",
    icon: "fa-solid fa-rss",
    children: [
      {
        title: "Danh mục tin tức",
        key: "post-category",
        url: "cms/management/post-category",
      },
      {
        title: "Tin tức/ bài viết",
        key: "posts",
        url: "cms/management/post",
      },
    ],
  },
  {
    title: "Quản lý văn bản",
    key: "document-management",
    icon: "fa-solid fa-file-lines",
    children: [
      {
        title: "Danh mục văn bản",
        key: "document-category",
        url: "cms/management/document-category",
      },
      {
        title: "Loại văn bản",
        key: "document-type",
        url: "cms/management/document-type",
      },
      {
        title: "Đơn vị phát hành",
        key: "document-publisher",
        url: "cms/management/document-publisher",
      },
      {
        title: "Văn bản",
        key: "document",
        url: "cms/management/document",
      },
    ],
  },
  {
    title: "Quản lý media",
    key: "media-management",
    icon: "fa-solid fa-image",
    children: [
      {
        title: "Video",
        key: "video",
        url: "cms/management/video",
      },
      {
        title: "Album ảnh",
        key: "image-album",
        url: "cms/management/image-album",
      },
    ],
  },

  {
    title: "Quản lý biểu đồ",
    key: "chart-management",
    icon: "fa-solid fa-chart-column",
    url: "cms/management/chart",
  },
  {
    title: "Quản lý điều hướng",
    key: "link-management",
    icon: "fa-solid fa-link",
    url: "cms/management/link",
  },
  {
    title: "Quản lý trang",
    key: "page-management",
    icon: "fa-solid fa-copy",
    url: "cms/management/page",
  },
  {
    title: "Quản lý hỏi đáp",
    key: "faq-management",
    icon: "fa-solid fa-circle-question",
    children: [
      {
        title: "Chuyên mục",
        key: "faq-category",
        url: "cms/management/faq-category",
      },
      {
        title: "Hỏi đáp",
        key: "faq",
        url: "cms/management/faq",
      },
    ],
  },
  {
    title: "Quản lý liên hệ",
    key: "contact-management",
    icon: "fa-solid fa-phone",
    url: "cms/management/contact",
  },
  {
    title: "Quản lý bình luận",
    key: "comment-management",
    icon: "fa-solid fa-comment-dots",
    url: "cms/management/comment",
  },
  {
    title: "Quản lý banner",
    key: "banner-management",
    icon: "fa-solid fa-flag",
    url: "cms/management/banner",
  },
  {
    title: "Quản lý người dùng",
    key: "user-management",
    icon: "fa-solid fa-user",
    children: [
      {
        title: "Nhóm quyền",
        key: "role",
        url: "cms/management/role",
      },
      {
        title: "Người dùng",
        key: "user",
        url: "cms/management/user",
      },
    ],
  },
  {
    title: "Hệ thống",
    key: "setting-management",
    icon: "fa-solid fa-gears",
    children: [
      {
        title: "Quản lý cấu hình",
        key: "config-management",
        url: "cms/management/setting/config",
      },
      {
        title: "Quản lý log",
        key: "log-management",
        url: "cms/management/setting/log",
      },
      {
        title: "Quản lý ngôn ngữ",
        key: "language-management",
        url: "cms/management/setting/language",
      },
      {
        title: "Quản lý giao diện",
        key: "intefact-management",
        url: "cms/management/setting/interface",
      },
    ],
  },
];
