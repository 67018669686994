/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { Dropdown, Menu, Skeleton, Tabs, notification } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  useGetAllNotifications,
  useUpdateOneNotification,
  useUpdateReadAllNotification,
} from "@api/notification";
import InfiniteScroll from "react-infinite-scroll-component";
import "moment/locale/vi";
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_TYPE,
} from "services/utils/constants";
import { history } from "index";
import style from "./style.module.scss";

const Notification = ({ setRefreshCount, setOpen }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 20;
  const [hasMore, setHasMore] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [isRead, setIsRead] = useState();
  const [reloadData, setReloadData] = useState(Math.random());

  const [selectedNotification, setSelectedNotification] = useState();
  // START get all my notifications
  const { refetch: refetchGetAllMyNotifications } = useGetAllNotifications({
    page,
    size: pageSize,
    sort: "createDate,desc",
    "isRead.equals": isRead,
  });

  useEffect(() => {
    setData([]);
    setPage(0);
    setReloadData(Math.random());
  }, []);

  useEffect(() => {
    refetchGetAllMyNotifications().then((response) => {
      if (response && response.data) {
        setTotalElements(response.data.totalElements);
        setData(data.concat(response.data.content));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isRead, reloadData, refetchGetAllMyNotifications]);

  useEffect(() => {
    if (
      (data.length <= 0 || data.length > 200 || data.length >= totalElements) &&
      page > 0
    ) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    if (totalElements < pageSize) {
      setHasMore(false);
    }
  }, [data, totalElements, page]);

  // END get all my notifications
  const { mutateAsync: readAllNotifications } = useUpdateReadAllNotification();
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link
              to="/personal-user/notifications"
              onClick={() => {
                if (setOpen) {
                  setOpen(false);
                }
              }}
            >
              <i className="fe fe-align-justify mr-2" /> Tất cả thông báo
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                readAllNotifications().then(() => {
                  setData([]);
                  setPage(0);
                  setReloadData(Math.random());
                  setRefreshCount(Math.random());
                });
              }}
              role="presentation"
            >
              <i className="fe fe-check mr-2" /> Đánh dấu tất cả đã đọc
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <Link
              rel="noopener noreferrer"
              to="/personal-user/setting-notifications"
              onClick={() => {
                if (setOpen) {
                  setOpen(false);
                }
              }}
            >
              <i className="fe fe-settings mr-2" /> Cài đặt thông báo
            </Link>
          ),
        },
      ]}
    />
  );
  const OperationsSlot = {
    right: (
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={["click"]}
        arrow
      >
        <EllipsisOutlined
          style={{
            cursor: "pointer",
            marginRight: 18,
            fontSize: 30,
          }}
        />
      </Dropdown>
    ),
  };
  const dropDownRead = (
    <Menu
      // onClick={e => e.preventDefault()}
      items={[
        {
          key: "markRead",
          label: (
            <div
              onClick={(e) => {
                e.stopPropagation();

                readNotification({ id: selectedNotification }).then(() => {
                  const index = data.findIndex(
                    (item) => item.id === selectedNotification
                  );
                  const newData = [...data];
                  newData[index].isRead = true;
                  setData(newData);
                  setRefreshCount(Math.random());
                });
              }}
              role="presentation"
            >
              <i className="fe fe-check mr-2" /> Đánh dấu đã đọc
            </div>
          ),
        },
      ]}
    />
  );

  const {
    // isLoading: isLoadingUpdate,
    mutateAsync: readNotification,
    // isSuccess: successUpdate,
  } = useUpdateOneNotification();

  const resetData = () => {
    setData([]);
    setPage(0);
    setTotalElements(0);
  };
  const fetchMoreData = () => {
    setPage((prev) => prev + 1);
  };

  const handleNotificationClick = (i) => {
    if (!i.isRead) {
      readNotification({ id: i.id }).then(() => {
        i.isRead = true;
        setRefreshCount(Math.random());
      });
    }
    if (i.type === NOTIFICATION_TYPE.DOCUMENT_PUBLISHED) {
      if (i.document) {
        history.push(`/documents/publish`);
        if (setOpen) {
          setOpen(false);
        }
      }
    } else if (i.type === NOTIFICATION_TYPE.DOCUMENT) {
      if (i.document) {
        if (i.isDocumentRecipient === true) {
          history.push(`/documents/${i.document.id}/view?receiver=1`);
          if (setOpen) {
            setOpen(false);
          }
        } else {
          history.push(`/documents/${i.document.id}/view`);
          if (setOpen) {
            setOpen(false);
          }
        }
      } else {
        notification.error({
          message: NOTIFICATION_ERROR_MESSAGE.DOCUMENT_IS_NULL,
        });
      }
    } else if (i.type === NOTIFICATION_TYPE.TICKET) {
      if (i.tickets) {
        history.push(`/e-ticket/${i.tickets.id}/view`);
        if (setOpen) {
          setOpen(false);
        }
      } else {
        notification.error({
          message: NOTIFICATION_ERROR_MESSAGE.TICKET_IS_NULL,
        });
      }
    } else if (i.type === NOTIFICATION_TYPE.DOCUMENT_COMMENT) {
      if (i.document && i.documentComment) {
        if (i.isDocumentRecipient === true) {
          history.push(
            `/documents/${i.document.id}/view?receiver=1#comment-${i.documentComment.id}`
          );
          if (setOpen) {
            setOpen(false);
          }
        } else {
          history.push(
            `/documents/${i.document.id}/view#comment-${i.documentComment.id}`
          );
          if (setOpen) {
            setOpen(false);
          }
        }
      } else {
        notification.error({
          message: NOTIFICATION_ERROR_MESSAGE.DOCUMENT_COMMENT_IS_NULL,
        });
      }
    } else if (i.type === NOTIFICATION_TYPE.TICKET_COMMENT) {
      if (i.tickets && i.ticketComments) {
        history.push(
          `/e-ticket/${i.tickets.id}/view#comment-${i.ticketComments.id}`
        );
        if (setOpen) {
          setOpen(false);
        }
      } else {
        notification.error({
          message: NOTIFICATION_ERROR_MESSAGE.TICKET_COMMENT_IS_NULL,
        });
      }
    }
  };

  return (
    <div>
      <Tabs
        key="notifications"
        tabBarExtraContent={OperationsSlot}
        title="Thông báo"
        className={`${style.tabs} kit-tabs-bordered`}
        destroyInactiveTabPane
        onChange={(e) => {
          resetData();
          if (e === "2") {
            setIsRead(false);
          } else {
            setIsRead();
          }
        }}
        items={[
          {
            key: "1",
            label: "Tất cả thông báo",
            children: (
              <InfiniteScroll
                key="11"
                dataLength={data.length}
                height={350}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Skeleton active />}
                // endMessage={<div style={{ textAlign: 'center' }}>Bạn đã xem hết thông báo</div>}
              >
                <div className="text-gray-6">
                  <ul className="list-unstyled">
                    {data?.map((i) => (
                      <li
                        className={style.my_li}
                        key={i.id}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNotificationClick(i);
                        }}
                        role="presentation"
                      >
                        <div
                          className={style.head}
                          style={{
                            wordBreak: "break-word",
                          }}
                        >
                          <div>
                            <p
                              className={style.title}
                              style={{
                                color: i && i.isRead ? "#c5bebe" : null,
                              }}
                            >
                              {i && i.isRead ? i.detail : <b>{i.detail}</b>}
                            </p>
                            <time className={style.time}>
                              {moment(i ? i.createDate : undefined)
                                .locale("vi")
                                .fromNow()}
                            </time>
                          </div>

                          {i && !i.isRead ? (
                            <Dropdown
                              key={Math.random()}
                              overlay={dropDownRead}
                              trigger={["click"]}
                              placement="bottomRight"
                              arrow
                            >
                              <EllipsisOutlined
                                className={style.my_ellipsis}
                                key={Math.random()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedNotification(i.id);
                                }}
                                style={{
                                  cursor: "pointer",
                                  fontSize: "24px",
                                }}
                              />
                            </Dropdown>
                          ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </InfiniteScroll>
            ),
          },
          {
            key: "2",
            label: "Chưa đọc",
            children: (
              <InfiniteScroll
                key="22"
                dataLength={data.length}
                height={350}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Skeleton active />}
                // endMessage={<div style={{ textAlign: 'center' }}>Bạn đã xem hết thông báo</div>}
              >
                <div className="text-gray-6">
                  <ul className="list-unstyled">
                    {data
                      ?.filter((i) => !i.isRead)
                      .map((i) => (
                        <li
                          className={style.my_li}
                          key={i.id}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(i);
                          }}
                          role="presentation"
                        >
                          <div
                            className={style.head}
                            style={{
                              wordBreak: "break-word",
                            }}
                          >
                            <div>
                              <p
                                className={style.title}
                                style={{
                                  color: i && i.isRead ? "#c5bebe" : null,
                                }}
                              >
                                {/* {i ? i.detail : null} */}
                                {i && i.isRead ? i.detail : <b>{i.detail}</b>}
                              </p>
                              <time className={style.time}>
                                {" "}
                                {moment(i ? i.createDate : null)
                                  .locale("vi")
                                  .fromNow()}
                              </time>
                            </div>
                            {i && !i.isRead ? (
                              <Dropdown
                                overlay={dropDownRead}
                                trigger={["click"]}
                                placement="bottomRight"
                                arrow
                                key={Math.random()}
                              >
                                <EllipsisOutlined
                                  className={style.my_ellipsis}
                                  key={Math.random()}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedNotification(i.id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                />
                              </Dropdown>
                            ) : null}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </InfiniteScroll>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Notification;
