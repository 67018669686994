/* eslint-disable */
/**
 * 
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  EmailSetting,
  GetAllMyNotificationsParams,
  GetAllNotificationsParams,
  NotificationDTO,
  NotificationVM,
  PageNotificationDTO,
  TelegramMessageDTO
} from './notification.schemas'
import { useCustomInstance } from './use-custom-instance';
import { customFormData } from './custom-form-data';



export const useSyncMailConfigHook = () => {
        const syncMailConfig = useCustomInstance<void>();

        return (
    emailSetting: EmailSetting,
 ) => {
        return syncMailConfig(
          {url: `/notification/api/syncMailConfig`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: emailSetting
    },
          );
        }
      }
    


export const useSyncMailConfigMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const syncMailConfig =  useSyncMailConfigHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, {data: EmailSetting}> = (props) => {
          const {data} = props ?? {};

          return  syncMailConfig(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SyncMailConfigMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>>
    export type SyncMailConfigMutationBody = EmailSetting
    export type SyncMailConfigMutationError = unknown

    export const useSyncMailConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSyncMailConfigHook>>>, TError,{data: EmailSetting}, TContext>, }
) => {

      const mutationOptions = useSyncMailConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Api for read all your notifications
 */
export const useUpdateReadAllNotificationHook = () => {
        const updateReadAllNotification = useCustomInstance<string>();

        return (
    
 ) => {
        return updateReadAllNotification(
          {url: `/notification/api/notifications/readAll`, method: 'put'
    },
          );
        }
      }
    


export const useUpdateReadAllNotificationMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateReadAllNotificationHook>>>, TError,TVariables, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateReadAllNotificationHook>>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateReadAllNotification =  useUpdateReadAllNotificationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateReadAllNotificationHook>>>, TVariables> = () => {
          

          return  updateReadAllNotification()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateReadAllNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReadAllNotificationHook>>>>
    
    export type UpdateReadAllNotificationMutationError = unknown

    /**
 * @summary Api for read all your notifications
 */
export const useUpdateReadAllNotification = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateReadAllNotificationHook>>>, TError,TVariables, TContext>, }
) => {

      const mutationOptions = useUpdateReadAllNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const useSendNotificationHook = () => {
        const sendNotification = useCustomInstance<void>();

        return (
    notificationVM: NotificationVM,
 ) => {
        return sendNotification(
          {url: `/notification/api/send-notification`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: notificationVM
    },
          );
        }
      }
    


export const useSendNotificationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendNotificationHook>>>, TError,{data: NotificationVM}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendNotificationHook>>>, TError,{data: NotificationVM}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const sendNotification =  useSendNotificationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendNotificationHook>>>, {data: NotificationVM}> = (props) => {
          const {data} = props ?? {};

          return  sendNotification(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SendNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendNotificationHook>>>>
    export type SendNotificationMutationBody = NotificationVM
    export type SendNotificationMutationError = unknown

    export const useSendNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendNotificationHook>>>, TError,{data: NotificationVM}, TContext>, }
) => {

      const mutationOptions = useSendNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get all your notifications
 */
export const useGetAllMyNotificationsHook = () => {
        const getAllMyNotifications = useCustomInstance<NotificationDTO[]>();

        return (
    params?: GetAllMyNotificationsParams,
 signal?: AbortSignal
) => {
        return getAllMyNotifications(
          {url: `/notification/api/notifications`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllMyNotificationsQueryKey = (params?: GetAllMyNotificationsParams,) => {
    
    return [`/notification/api/notifications`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllMyNotificationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>, TError = unknown>(params?: GetAllMyNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllMyNotificationsQueryKey(params);

  const getAllMyNotifications =  useGetAllMyNotificationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>> = ({ signal }) => getAllMyNotifications(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllMyNotificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>>
export type GetAllMyNotificationsQueryError = unknown

/**
 * @summary Get all your notifications
 */
export const useGetAllMyNotifications = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>, TError = unknown>(
 params?: GetAllMyNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllMyNotificationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllMyNotificationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useCreateNotificationHook = () => {
        const createNotification = useCustomInstance<NotificationDTO>();

        return (
    notificationDTO: NotificationDTO,
 ) => {
        return createNotification(
          {url: `/notification/api/notifications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: notificationDTO
    },
          );
        }
      }
    


export const useCreateNotificationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNotificationHook>>>, TError,{data: NotificationDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNotificationHook>>>, TError,{data: NotificationDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createNotification =  useCreateNotificationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateNotificationHook>>>, {data: NotificationDTO}> = (props) => {
          const {data} = props ?? {};

          return  createNotification(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateNotificationHook>>>>
    export type CreateNotificationMutationBody = NotificationDTO
    export type CreateNotificationMutationError = unknown

    export const useCreateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNotificationHook>>>, TError,{data: NotificationDTO}, TContext>, }
) => {

      const mutationOptions = useCreateNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get url for telegram bot
 */
export const useGetTelegramUrlHook = () => {
        const getTelegramUrl = useCustomInstance<string>();

        return (
    
 signal?: AbortSignal
) => {
        return getTelegramUrl(
          {url: `/notification/api/notifications/telegram`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetTelegramUrlQueryKey = () => {
    
    return [`/notification/api/notifications/telegram`] as const;
    }

    
export const useGetTelegramUrlQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTelegramUrlQueryKey();

  const getTelegramUrl =  useGetTelegramUrlHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>> = ({ signal }) => getTelegramUrl(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTelegramUrlQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>>
export type GetTelegramUrlQueryError = unknown

/**
 * @summary Get url for telegram bot
 */
export const useGetTelegramUrl = <TData = Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTelegramUrlHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetTelegramUrlQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Send notification by telegram bot
 */
export const useSendTelegramMessageHook = () => {
        const sendTelegramMessage = useCustomInstance<void>();

        return (
    telegramMessageDTO: TelegramMessageDTO,
 ) => {
        return sendTelegramMessage(
          {url: `/notification/api/notifications/telegram`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: telegramMessageDTO
    },
          );
        }
      }
    


export const useSendTelegramMessageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessageHook>>>, TError,{data: TelegramMessageDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessageHook>>>, TError,{data: TelegramMessageDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const sendTelegramMessage =  useSendTelegramMessageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessageHook>>>, {data: TelegramMessageDTO}> = (props) => {
          const {data} = props ?? {};

          return  sendTelegramMessage(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SendTelegramMessageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessageHook>>>>
    export type SendTelegramMessageMutationBody = TelegramMessageDTO
    export type SendTelegramMessageMutationError = unknown

    /**
 * @summary Send notification by telegram bot
 */
export const useSendTelegramMessage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessageHook>>>, TError,{data: TelegramMessageDTO}, TContext>, }
) => {

      const mutationOptions = useSendTelegramMessageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send notification by telegram bot
 */
export const useSendTelegramMessage1Hook = () => {
        const sendTelegramMessage1 = useCustomInstance<void>();

        return (
    telegramMessageDTO: TelegramMessageDTO,
 ) => {
        return sendTelegramMessage1(
          {url: `/notification/api/notifications/schedule/telegram`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: telegramMessageDTO
    },
          );
        }
      }
    


export const useSendTelegramMessage1MutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessage1Hook>>>, TError,{data: TelegramMessageDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessage1Hook>>>, TError,{data: TelegramMessageDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const sendTelegramMessage1 =  useSendTelegramMessage1Hook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessage1Hook>>>, {data: TelegramMessageDTO}> = (props) => {
          const {data} = props ?? {};

          return  sendTelegramMessage1(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SendTelegramMessage1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessage1Hook>>>>
    export type SendTelegramMessage1MutationBody = TelegramMessageDTO
    export type SendTelegramMessage1MutationError = unknown

    /**
 * @summary Send notification by telegram bot
 */
export const useSendTelegramMessage1 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendTelegramMessage1Hook>>>, TError,{data: TelegramMessageDTO}, TContext>, }
) => {

      const mutationOptions = useSendTelegramMessage1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Api for check read only a notification
 */
export const useUpdateOneNotificationHook = () => {
        const updateOneNotification = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return updateOneNotification(
          {url: `/notification/api/notifications/read/${id}`, method: 'patch'
    },
          );
        }
      }
    


export const useUpdateOneNotificationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOneNotificationHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOneNotificationHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateOneNotification =  useUpdateOneNotificationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOneNotificationHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  updateOneNotification(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateOneNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOneNotificationHook>>>>
    
    export type UpdateOneNotificationMutationError = unknown

    /**
 * @summary Api for check read only a notification
 */
export const useUpdateOneNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOneNotificationHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useUpdateOneNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Count your unread notifications
 */
export const useCountMyNotificationsHook = () => {
        const countMyNotifications = useCustomInstance<number>();

        return (
    
 signal?: AbortSignal
) => {
        return countMyNotifications(
          {url: `/notification/api/notifications/count`, method: 'get', signal
    },
          );
        }
      }
    

export const getCountMyNotificationsQueryKey = () => {
    
    return [`/notification/api/notifications/count`] as const;
    }

    
export const useCountMyNotificationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCountMyNotificationsQueryKey();

  const countMyNotifications =  useCountMyNotificationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>> = ({ signal }) => countMyNotifications(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type CountMyNotificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>>
export type CountMyNotificationsQueryError = unknown

/**
 * @summary Count your unread notifications
 */
export const useCountMyNotifications = <TData = Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountMyNotificationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useCountMyNotificationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useGetAllNotificationsHook = () => {
        const getAllNotifications = useCustomInstance<PageNotificationDTO>();

        return (
    params?: GetAllNotificationsParams,
 signal?: AbortSignal
) => {
        return getAllNotifications(
          {url: `/notification/api/myNotifications`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllNotificationsQueryKey = (params?: GetAllNotificationsParams,) => {
    
    return [`/notification/api/myNotifications`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllNotificationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>, TError = unknown>(params?: GetAllNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllNotificationsQueryKey(params);

  const getAllNotifications =  useGetAllNotificationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>> = ({ signal }) => getAllNotifications(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllNotificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>>
export type GetAllNotificationsQueryError = unknown

export const useGetAllNotifications = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>, TError = unknown>(
 params?: GetAllNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNotificationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllNotificationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


