import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Layout } from "antd";
import classNames from "classnames";
import store from "store";
import PerfectScrollbar from "react-perfect-scrollbar";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/userSlice";
import {
  changeSettings,
  selectCmsSettings,
} from "admin-cms/redux/adminCmsSettingsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style.module.scss";
import { cmsMenu } from "../data";

const flatMenu = (menu) => {
  if (!menu) return [];

  const newMenu = menu.map((item) => [
    { title: item.title, url: `/${item.url}`, key: item.key },
    ...flatMenu(item?.children),
  ]);
  return newMenu.flat();
};

const flatternMenu = flatMenu(cmsMenu);

const MenuLeft = () => {
  const dispatch = useDispatch();
  const {
    isMenuCollapsed,
    isMobileView,
    isMenuUnfixed,
    isMenuShadow,
    leftMenuWidth,
    menuColor,
    logo,
  } = useSelector(selectCmsSettings);
  const { role } = useSelector(selectUser);
  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState(
    store.get("app.menu.selectedKeys") || []
  );
  const [openedKeys, setOpenedKeys] = useState(
    store.get("app.menu.openedKeys") || []
  );

  const applySelectedKeys = () => {
    const selectedItem = find(flatternMenu, ["url", pathname]);
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  useEffect(() => {
    applySelectedKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, cmsMenu]);

  const onCollapse = (value, type) => {
    if (type === "responsive" && isMenuCollapsed) {
      return;
    }
    dispatch(
      changeSettings({ setting: "isMenuCollapsed", value: !isMenuCollapsed })
    );
    setOpenedKeys([]);
  };

  const onOpenChange = (keys) => {
    store.set("app.menu.openedKeys", keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e) => {
    store.set("app.menu.selectedKeys", [e.key]);
    setSelectedKeys([e.key]);
  };

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, count } = item;
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && (
                  <span className="badge badge-success ml-2">{count}</span>
                )}
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    className={`${style.icon} icon-collapsed-hidden`}
                  />
                  // <span
                  //   className={`${icon} ${style.icon} icon-collapsed-hidden`}
                  // />
                )}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && (
                  <span className="badge badge-success ml-2">{count}</span>
                )}
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    className={`${style.icon} icon-collapsed-hidden`}
                  />
                )}
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && (
            <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />
          )}
        </Menu.Item>
      );
    };

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && (
                <span className="badge badge-success ml-2">
                  {menuItem.count}
                </span>
              )}
              {menuItem.icon && (
                <FontAwesomeIcon
                  icon={menuItem.icon}
                  className={`${style.icon}`}
                />
              )}
            </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return cmsMenu.map((menuItem) => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && (
              <span className="badge badge-success ml-2">{menuItem.count}</span>
            )}
            {menuItem.icon && (
              <FontAwesomeIcon
                icon={menuItem.icon}
                className={`${style.icon}`}
              />
              // <span className={`${menuItem.icon} ${style.icon}`} />
            )}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: "lg",
      };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === "white",
        [style.gray]: menuColor === "gray",
        [style.dark]: menuColor === "dark",
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height:
            isMobileView || isMenuUnfixed
              ? "calc(100% - 64px)"
              : "calc(100% - 110px)",
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <img
              src="/resources/images/tf-logo.png"
              height={50}
              className="mr-2"
              alt="EVN Portal"
            />
            {/* <div className={style.name}>{logo}</div> */}
            {/* {logo === "NRLDC" && <div className={style.descr}>EVN</div>} */}
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  );
};

export default MenuLeft;
