/* eslint-disable */
/**
 * 
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddBannerDTO,
  AddBannerImageDTO,
  AlbumImageDetail,
  AlbumsDTO,
  AuditEntity,
  AuditLogDTO,
  BannerDetails,
  ChartDataLoadsDTO,
  ChartsDTO,
  ContactsDTO,
  DocumentCategoriesDTO,
  DocumentCategoriesHierarchy,
  DocumentFilesDTO,
  DocumentPublishersDTO,
  DocumentTypesDTO,
  DocumentsDTO,
  EmailSetting,
  FaqCategoriesDTO,
  FaqCategoriesPatchDTO,
  FaqsDTO,
  FaqsPatchDTO,
  GeneralSetting,
  GetAllAlbumsParams,
  GetAllBannersParams,
  GetAllChartDataLoadsParams,
  GetAllChartsParams,
  GetAllContactsParams,
  GetAllDocumentCategoriesParams,
  GetAllDocumentFilesParams,
  GetAllDocumentPublishersParams,
  GetAllDocumentTypesParams,
  GetAllDocumentsParams,
  GetAllFaqCategoriesParams,
  GetAllFaqsParams,
  GetAllImagesParams,
  GetAllLanguagesParams,
  GetAllLayoutsParams,
  GetAllNavigationLinksParams,
  GetAllNavigationsParams,
  GetAllPagesParams,
  GetAllPostCategoriesParams,
  GetAllPostCommentsParams,
  GetAllPostsParams,
  GetAllRolesParams,
  GetAllUsersParams,
  GetAllVideosParams,
  GetChangesParams,
  GetFileResponse200,
  GetFileResponseParams,
  GetPrevVersionParams,
  GetSeoValuesParams,
  GetSetting200,
  IUAlbumDTO,
  IUAlbumImagesDTO,
  IUChartDTO,
  IUDocumentCategoriesDTO,
  IUDocumentsDTO,
  IUNavigationLinksDTO,
  IUPostCategoriesDTO,
  IUPostDTO,
  IURoleGroupDTO,
  IUVideoDTO,
  LanguageModel,
  LanguagesDTO,
  LayoutsDTO,
  ModuleAndActionDTO,
  NavigationLinksDTO,
  NavigationLinksHierarchy,
  NavigationsDTO,
  PageAlbumImageSummary,
  PageAlbumsDTO,
  PageAuditLogDTO,
  PageBannerSummary,
  PageChartsDTO,
  PageContactsDTO,
  PageDocumentPublishersDTO,
  PageDocumentTypesDTO,
  PageDocumentsDTO,
  PageFaqCategoriesDTO,
  PageFaqsDTO,
  PageLanguagesDTO,
  PageLayoutsDTO,
  PageNavigationsDTO,
  PagePagesDTO,
  PagePostCommentsDTO,
  PagePostsDTO,
  PageRoleGroupSummary,
  PageUserSummary,
  PageVideoSummary,
  PagesDTO,
  PostCategoriesDTO,
  PostCategoriesHierarchy,
  PostCommentsDTO,
  Posts,
  PostsDTO,
  RoleGroupDetail,
  SeoValuesDTO,
  ServerSetting,
  SettingDTO,
  UpdateBannerDTO,
  UpdateBannerImageDTO,
  UpdateDocumentStatusParams,
  UpdateNavigationLinkStatusParams,
  UpdateSeoSettingDto,
  UpdateSortOrderParams,
  UpdateUserDTO,
  UploadImage1Body,
  UploadImageBody,
  UserAuthorities,
  UsersDTO,
  VideosDTO
} from './admincms.schemas'
import { useCustomInstance } from './use-custom-instance';
import { customFormData } from './custom-form-data';



/**
 * @summary Cập nhật cấu hình server
 */
export const useUpdateServerSettingHook = () => {
        const updateServerSetting = useCustomInstance<SettingDTO>();

        return (
    serverSetting: ServerSetting,
 ) => {
        return updateServerSetting(
          {url: `/admincms/api/settings/server`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: serverSetting
    },
          );
        }
      }
    


export const useUpdateServerSettingMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateServerSettingHook>>>, TError,{data: ServerSetting}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateServerSettingHook>>>, TError,{data: ServerSetting}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateServerSetting =  useUpdateServerSettingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateServerSettingHook>>>, {data: ServerSetting}> = (props) => {
          const {data} = props ?? {};

          return  updateServerSetting(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateServerSettingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateServerSettingHook>>>>
    export type UpdateServerSettingMutationBody = ServerSetting
    export type UpdateServerSettingMutationError = unknown

    /**
 * @summary Cập nhật cấu hình server
 */
export const useUpdateServerSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateServerSettingHook>>>, TError,{data: ServerSetting}, TContext>, }
) => {

      const mutationOptions = useUpdateServerSettingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật cấu hình SEO
 */
export const useUpdateSeoSettingHook = () => {
        const updateSeoSetting = useCustomInstance<SettingDTO>();

        return (
    updateSeoSettingDto: UpdateSeoSettingDto,
 ) => {const formData = customFormData(updateSeoSettingDto)
        return updateSeoSetting(
          {url: `/admincms/api/settings/seo`, method: 'put',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUpdateSeoSettingMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSeoSettingHook>>>, TError,{data: UpdateSeoSettingDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSeoSettingHook>>>, TError,{data: UpdateSeoSettingDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateSeoSetting =  useUpdateSeoSettingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateSeoSettingHook>>>, {data: UpdateSeoSettingDto}> = (props) => {
          const {data} = props ?? {};

          return  updateSeoSetting(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateSeoSettingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSeoSettingHook>>>>
    export type UpdateSeoSettingMutationBody = UpdateSeoSettingDto
    export type UpdateSeoSettingMutationError = unknown

    /**
 * @summary Cập nhật cấu hình SEO
 */
export const useUpdateSeoSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSeoSettingHook>>>, TError,{data: UpdateSeoSettingDto}, TContext>, }
) => {

      const mutationOptions = useUpdateSeoSettingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật cấu hình chung
 */
export const useUpdateGeneralSettingHook = () => {
        const updateGeneralSetting = useCustomInstance<SettingDTO>();

        return (
    generalSetting: GeneralSetting,
 ) => {
        return updateGeneralSetting(
          {url: `/admincms/api/settings/general`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: generalSetting
    },
          );
        }
      }
    


export const useUpdateGeneralSettingMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGeneralSettingHook>>>, TError,{data: GeneralSetting}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGeneralSettingHook>>>, TError,{data: GeneralSetting}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateGeneralSetting =  useUpdateGeneralSettingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateGeneralSettingHook>>>, {data: GeneralSetting}> = (props) => {
          const {data} = props ?? {};

          return  updateGeneralSetting(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateGeneralSettingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateGeneralSettingHook>>>>
    export type UpdateGeneralSettingMutationBody = GeneralSetting
    export type UpdateGeneralSettingMutationError = unknown

    /**
 * @summary Cập nhật cấu hình chung
 */
export const useUpdateGeneralSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGeneralSettingHook>>>, TError,{data: GeneralSetting}, TContext>, }
) => {

      const mutationOptions = useUpdateGeneralSettingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật cấu hình email
 */
export const useUpdateEmailSettingHook = () => {
        const updateEmailSetting = useCustomInstance<SettingDTO>();

        return (
    emailSetting: EmailSetting,
 ) => {
        return updateEmailSetting(
          {url: `/admincms/api/settings/email`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: emailSetting
    },
          );
        }
      }
    


export const useUpdateEmailSettingMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmailSettingHook>>>, TError,{data: EmailSetting}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmailSettingHook>>>, TError,{data: EmailSetting}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateEmailSetting =  useUpdateEmailSettingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEmailSettingHook>>>, {data: EmailSetting}> = (props) => {
          const {data} = props ?? {};

          return  updateEmailSetting(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateEmailSettingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEmailSettingHook>>>>
    export type UpdateEmailSettingMutationBody = EmailSetting
    export type UpdateEmailSettingMutationError = unknown

    /**
 * @summary Cập nhật cấu hình email
 */
export const useUpdateEmailSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmailSettingHook>>>, TError,{data: EmailSetting}, TContext>, }
) => {

      const mutationOptions = useUpdateEmailSettingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Duyệt bài viết
 */
export const useApprovePostHook = () => {
        const approvePost = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return approvePost(
          {url: `/admincms/api/posts/approval/${id}`, method: 'put'
    },
          );
        }
      }
    


export const useApprovePostMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApprovePostHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApprovePostHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const approvePost =  useApprovePostHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useApprovePostHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  approvePost(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApprovePostMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useApprovePostHook>>>>
    
    export type ApprovePostMutationError = unknown

    /**
 * @summary Duyệt bài viết
 */
export const useApprovePost = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApprovePostHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useApprovePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin trang
 */
export const useGetPagesHook = () => {
        const getPages = useCustomInstance<PagesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getPages(
          {url: `/admincms/api/pages/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPagesQueryKey = (id: number,) => {
    
    return [`/admincms/api/pages/${id}`] as const;
    }

    
export const useGetPagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPagesQueryKey(id);

  const getPages =  useGetPagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>> = ({ signal }) => getPages(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>>
export type GetPagesQueryError = unknown

/**
 * @summary Lấy thông tin trang
 */
export const useGetPages = <TData = Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPagesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật trang
 */
export const useUpdatePagesHook = () => {
        const updatePages = useCustomInstance<PagesDTO>();

        return (
    id: number,
    pagesDTO: PagesDTO,
 ) => {
        return updatePages(
          {url: `/admincms/api/pages/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: pagesDTO
    },
          );
        }
      }
    


export const useUpdatePagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updatePages =  useUpdatePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, {id: number;data: PagesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updatePages(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdatePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>>
    export type UpdatePagesMutationBody = PagesDTO
    export type UpdatePagesMutationError = unknown

    /**
 * @summary Cập nhật trang
 */
export const useUpdatePages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdatePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa trang
 */
export const useDeletePagesHook = () => {
        const deletePages = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deletePages(
          {url: `/admincms/api/pages/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeletePagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePagesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePagesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deletePages =  useDeletePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePagesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deletePages(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePagesHook>>>>
    
    export type DeletePagesMutationError = unknown

    /**
 * @summary Xóa trang
 */
export const useDeletePages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePagesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeletePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin trang
 */
export const usePartialUpdatePagesHook = () => {
        const partialUpdatePages = useCustomInstance<PagesDTO>();

        return (
    id: number,
    pagesDTO: PagesDTO,
 ) => {
        return partialUpdatePages(
          {url: `/admincms/api/pages/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: pagesDTO
    },
          );
        }
      }
    


export const usePartialUpdatePagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdatePages =  usePartialUpdatePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePagesHook>>>, {id: number;data: PagesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdatePages(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdatePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePagesHook>>>>
    export type PartialUpdatePagesMutationBody = PagesDTO
    export type PartialUpdatePagesMutationError = unknown

    /**
 * @summary Cập nhật thông tin trang
 */
export const usePartialUpdatePages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePagesHook>>>, TError,{id: number;data: PagesDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdatePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin điều hướng
 */
export const useGetNavigationsHook = () => {
        const getNavigations = useCustomInstance<NavigationsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getNavigations(
          {url: `/admincms/api/navigations/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetNavigationsQueryKey = (id: number,) => {
    
    return [`/admincms/api/navigations/${id}`] as const;
    }

    
export const useGetNavigationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNavigationsQueryKey(id);

  const getNavigations =  useGetNavigationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>> = ({ signal }) => getNavigations(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNavigationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>>
export type GetNavigationsQueryError = unknown

/**
 * @summary Lấy thông tin điều hướng
 */
export const useGetNavigations = <TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetNavigationsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật thông tin điều hướng
 */
export const useUpdateNavigationsHook = () => {
        const updateNavigations = useCustomInstance<NavigationsDTO>();

        return (
    id: number,
    navigationsDTO: NavigationsDTO,
 ) => {
        return updateNavigations(
          {url: `/admincms/api/navigations/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: navigationsDTO
    },
          );
        }
      }
    


export const useUpdateNavigationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateNavigations =  useUpdateNavigationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationsHook>>>, {id: number;data: NavigationsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateNavigations(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateNavigationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationsHook>>>>
    export type UpdateNavigationsMutationBody = NavigationsDTO
    export type UpdateNavigationsMutationError = unknown

    /**
 * @summary Cập nhật thông tin điều hướng
 */
export const useUpdateNavigations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateNavigationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa điều hướng
 */
export const useDeleteNavigationsHook = () => {
        const deleteNavigations = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteNavigations(
          {url: `/admincms/api/navigations/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteNavigationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteNavigations =  useDeleteNavigationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteNavigations(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteNavigationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationsHook>>>>
    
    export type DeleteNavigationsMutationError = unknown

    /**
 * @summary Xóa điều hướng
 */
export const useDeleteNavigations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteNavigationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin điều hướng
 */
export const usePartialUpdateNavigationsHook = () => {
        const partialUpdateNavigations = useCustomInstance<NavigationsDTO>();

        return (
    id: number,
    navigationsDTO: NavigationsDTO,
 ) => {
        return partialUpdateNavigations(
          {url: `/admincms/api/navigations/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: navigationsDTO
    },
          );
        }
      }
    


export const usePartialUpdateNavigationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateNavigations =  usePartialUpdateNavigationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateNavigationsHook>>>, {id: number;data: NavigationsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateNavigations(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateNavigationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateNavigationsHook>>>>
    export type PartialUpdateNavigationsMutationBody = NavigationsDTO
    export type PartialUpdateNavigationsMutationError = unknown

    /**
 * @summary Cập nhật thông tin điều hướng
 */
export const usePartialUpdateNavigations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateNavigationsHook>>>, TError,{id: number;data: NavigationsDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateNavigationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin liên kết
 */
export const useGetNavigationLinksHook = () => {
        const getNavigationLinks = useCustomInstance<NavigationLinksDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getNavigationLinks(
          {url: `/admincms/api/navigation-links/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetNavigationLinksQueryKey = (id: number,) => {
    
    return [`/admincms/api/navigation-links/${id}`] as const;
    }

    
export const useGetNavigationLinksQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNavigationLinksQueryKey(id);

  const getNavigationLinks =  useGetNavigationLinksHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>> = ({ signal }) => getNavigationLinks(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNavigationLinksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>>
export type GetNavigationLinksQueryError = unknown

/**
 * @summary Lấy thông tin liên kết
 */
export const useGetNavigationLinks = <TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationLinksHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetNavigationLinksQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật thông tin liên kết
 */
export const useUpdateNavigationLinksHook = () => {
        const updateNavigationLinks = useCustomInstance<NavigationLinksDTO>();

        return (
    id: number,
    iUNavigationLinksDTO: IUNavigationLinksDTO,
 ) => {const formData = customFormData(iUNavigationLinksDTO)
        return updateNavigationLinks(
          {url: `/admincms/api/navigation-links/${id}`, method: 'put',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUpdateNavigationLinksMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinksHook>>>, TError,{id: number;data: IUNavigationLinksDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinksHook>>>, TError,{id: number;data: IUNavigationLinksDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateNavigationLinks =  useUpdateNavigationLinksHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinksHook>>>, {id: number;data: IUNavigationLinksDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateNavigationLinks(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateNavigationLinksMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinksHook>>>>
    export type UpdateNavigationLinksMutationBody = IUNavigationLinksDTO
    export type UpdateNavigationLinksMutationError = unknown

    /**
 * @summary Cập nhật thông tin liên kết
 */
export const useUpdateNavigationLinks = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinksHook>>>, TError,{id: number;data: IUNavigationLinksDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateNavigationLinksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa liên kết
 */
export const useDeleteNavigationLinksHook = () => {
        const deleteNavigationLinks = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteNavigationLinks(
          {url: `/admincms/api/navigation-links/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteNavigationLinksMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationLinksHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationLinksHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteNavigationLinks =  useDeleteNavigationLinksHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationLinksHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteNavigationLinks(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteNavigationLinksMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationLinksHook>>>>
    
    export type DeleteNavigationLinksMutationError = unknown

    /**
 * @summary Xóa liên kết
 */
export const useDeleteNavigationLinks = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteNavigationLinksHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteNavigationLinksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật vị trí liên kết
 */
export const useUpdateSortOrderHook = () => {
        const updateSortOrder = useCustomInstance<NavigationLinksDTO>();

        return (
    id: number,
    params?: UpdateSortOrderParams,
 ) => {
        return updateSortOrder(
          {url: `/admincms/api/navigation-links/${id}`, method: 'patch',
        params
    },
          );
        }
      }
    


export const useUpdateSortOrderMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSortOrderHook>>>, TError,{id: number;params?: UpdateSortOrderParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSortOrderHook>>>, TError,{id: number;params?: UpdateSortOrderParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateSortOrder =  useUpdateSortOrderHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateSortOrderHook>>>, {id: number;params?: UpdateSortOrderParams}> = (props) => {
          const {id,params} = props ?? {};

          return  updateSortOrder(id,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateSortOrderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSortOrderHook>>>>
    
    export type UpdateSortOrderMutationError = unknown

    /**
 * @summary Cập nhật vị trí liên kết
 */
export const useUpdateSortOrder = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSortOrderHook>>>, TError,{id: number;params?: UpdateSortOrderParams}, TContext>, }
) => {

      const mutationOptions = useUpdateSortOrderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật trạng thái liên kết
 */
export const useUpdateNavigationLinkStatusHook = () => {
        const updateNavigationLinkStatus = useCustomInstance<void>();

        return (
    id: number,
    params: UpdateNavigationLinkStatusParams,
 ) => {
        return updateNavigationLinkStatus(
          {url: `/admincms/api/navigation-links/${id}/status`, method: 'put',
        params
    },
          );
        }
      }
    


export const useUpdateNavigationLinkStatusMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinkStatusHook>>>, TError,{id: number;params: UpdateNavigationLinkStatusParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinkStatusHook>>>, TError,{id: number;params: UpdateNavigationLinkStatusParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateNavigationLinkStatus =  useUpdateNavigationLinkStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinkStatusHook>>>, {id: number;params: UpdateNavigationLinkStatusParams}> = (props) => {
          const {id,params} = props ?? {};

          return  updateNavigationLinkStatus(id,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateNavigationLinkStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinkStatusHook>>>>
    
    export type UpdateNavigationLinkStatusMutationError = unknown

    /**
 * @summary Cập nhật trạng thái liên kết
 */
export const useUpdateNavigationLinkStatus = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateNavigationLinkStatusHook>>>, TError,{id: number;params: UpdateNavigationLinkStatusParams}, TContext>, }
) => {

      const mutationOptions = useUpdateNavigationLinkStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật cấu hình giao diện
 */
export const useUpdateLayoutsHook = () => {
        const updateLayouts = useCustomInstance<LayoutsDTO>();

        return (
    code: string,
    updateLayoutsBody: string,
 ) => {
        return updateLayouts(
          {url: `/admincms/api/layouts/settings/${code}`, method: 'put',
      headers: {'Content-Type': 'text/plain', },
      data: updateLayoutsBody
    },
          );
        }
      }
    


export const useUpdateLayoutsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLayoutsHook>>>, TError,{code: string;data: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLayoutsHook>>>, TError,{code: string;data: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateLayouts =  useUpdateLayoutsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateLayoutsHook>>>, {code: string;data: string}> = (props) => {
          const {code,data} = props ?? {};

          return  updateLayouts(code,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateLayoutsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateLayoutsHook>>>>
    export type UpdateLayoutsMutationBody = string
    export type UpdateLayoutsMutationError = unknown

    /**
 * @summary Cập nhật cấu hình giao diện
 */
export const useUpdateLayouts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLayoutsHook>>>, TError,{code: string;data: string}, TContext>, }
) => {

      const mutationOptions = useUpdateLayoutsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin ngôn ngữ
 */
export const useGetLanguagesHook = () => {
        const getLanguages = useCustomInstance<LanguagesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getLanguages(
          {url: `/admincms/api/languages/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetLanguagesQueryKey = (id: number,) => {
    
    return [`/admincms/api/languages/${id}`] as const;
    }

    
export const useGetLanguagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLanguagesQueryKey(id);

  const getLanguages =  useGetLanguagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>> = ({ signal }) => getLanguages(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLanguagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>>
export type GetLanguagesQueryError = unknown

/**
 * @summary Lấy thông tin ngôn ngữ
 */
export const useGetLanguages = <TData = Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLanguagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetLanguagesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật ngôn ngữ
 */
export const useUpdateLanguagesHook = () => {
        const updateLanguages = useCustomInstance<LanguagesDTO>();

        return (
    id: number,
    languagesDTO: LanguagesDTO,
 ) => {
        return updateLanguages(
          {url: `/admincms/api/languages/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: languagesDTO
    },
          );
        }
      }
    


export const useUpdateLanguagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateLanguages =  useUpdateLanguagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateLanguagesHook>>>, {id: number;data: LanguagesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateLanguages(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateLanguagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateLanguagesHook>>>>
    export type UpdateLanguagesMutationBody = LanguagesDTO
    export type UpdateLanguagesMutationError = unknown

    /**
 * @summary Cập nhật ngôn ngữ
 */
export const useUpdateLanguages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateLanguagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa ngôn ngữ
 */
export const useDeleteLanguagesHook = () => {
        const deleteLanguages = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteLanguages(
          {url: `/admincms/api/languages/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteLanguagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLanguagesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLanguagesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteLanguages =  useDeleteLanguagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteLanguagesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteLanguages(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteLanguagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteLanguagesHook>>>>
    
    export type DeleteLanguagesMutationError = unknown

    /**
 * @summary Xóa ngôn ngữ
 */
export const useDeleteLanguages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLanguagesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteLanguagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin ngôn ngữ
 */
export const usePartialUpdateLanguagesHook = () => {
        const partialUpdateLanguages = useCustomInstance<LanguagesDTO>();

        return (
    id: number,
    languagesDTO: LanguagesDTO,
 ) => {
        return partialUpdateLanguages(
          {url: `/admincms/api/languages/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: languagesDTO
    },
          );
        }
      }
    


export const usePartialUpdateLanguagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateLanguages =  usePartialUpdateLanguagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLanguagesHook>>>, {id: number;data: LanguagesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateLanguages(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateLanguagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLanguagesHook>>>>
    export type PartialUpdateLanguagesMutationBody = LanguagesDTO
    export type PartialUpdateLanguagesMutationError = unknown

    /**
 * @summary Cập nhật thông tin ngôn ngữ
 */
export const usePartialUpdateLanguages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLanguagesHook>>>, TError,{id: number;data: LanguagesDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateLanguagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin văn bản
 */
export const useGetDocumentsHook = () => {
        const getDocuments = useCustomInstance<DocumentsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getDocuments(
          {url: `/admincms/api/documents/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetDocumentsQueryKey = (id: number,) => {
    
    return [`/admincms/api/documents/${id}`] as const;
    }

    
export const useGetDocumentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentsQueryKey(id);

  const getDocuments =  useGetDocumentsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>> = ({ signal }) => getDocuments(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>>
export type GetDocumentsQueryError = unknown

/**
 * @summary Lấy thông tin văn bản
 */
export const useGetDocuments = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật văn bản
 */
export const useUpdateDocumentsHook = () => {
        const updateDocuments = useCustomInstance<DocumentsDTO>();

        return (
    id: number,
    iUDocumentsDTO: IUDocumentsDTO,
 ) => {const formData = customFormData(iUDocumentsDTO)
        return updateDocuments(
          {url: `/admincms/api/documents/${id}`, method: 'put',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUpdateDocumentsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentsHook>>>, TError,{id: number;data: IUDocumentsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentsHook>>>, TError,{id: number;data: IUDocumentsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocuments =  useUpdateDocumentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentsHook>>>, {id: number;data: IUDocumentsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocuments(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentsHook>>>>
    export type UpdateDocumentsMutationBody = IUDocumentsDTO
    export type UpdateDocumentsMutationError = unknown

    /**
 * @summary Cập nhật văn bản
 */
export const useUpdateDocuments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentsHook>>>, TError,{id: number;data: IUDocumentsDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa văn bản
 */
export const useDeleteDocumentsHook = () => {
        const deleteDocuments = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteDocuments(
          {url: `/admincms/api/documents/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteDocumentsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteDocuments =  useDeleteDocumentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocuments(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>>
    
    export type DeleteDocumentsMutationError = unknown

    /**
 * @summary Xóa văn bản
 */
export const useDeleteDocuments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật trạng thái văn bản
 */
export const useUpdateDocumentStatusHook = () => {
        const updateDocumentStatus = useCustomInstance<void>();

        return (
    id: number,
    params: UpdateDocumentStatusParams,
 ) => {
        return updateDocumentStatus(
          {url: `/admincms/api/documents/${id}/status`, method: 'put',
        params
    },
          );
        }
      }
    


export const useUpdateDocumentStatusMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentStatusHook>>>, TError,{id: number;params: UpdateDocumentStatusParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentStatusHook>>>, TError,{id: number;params: UpdateDocumentStatusParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocumentStatus =  useUpdateDocumentStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentStatusHook>>>, {id: number;params: UpdateDocumentStatusParams}> = (props) => {
          const {id,params} = props ?? {};

          return  updateDocumentStatus(id,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentStatusHook>>>>
    
    export type UpdateDocumentStatusMutationError = unknown

    /**
 * @summary Cập nhật trạng thái văn bản
 */
export const useUpdateDocumentStatus = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentStatusHook>>>, TError,{id: number;params: UpdateDocumentStatusParams}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin loại văn bản
 */
export const useGetDocumentTypesHook = () => {
        const getDocumentTypes = useCustomInstance<DocumentTypesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getDocumentTypes(
          {url: `/admincms/api/document-types/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetDocumentTypesQueryKey = (id: number,) => {
    
    return [`/admincms/api/document-types/${id}`] as const;
    }

    
export const useGetDocumentTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentTypesQueryKey(id);

  const getDocumentTypes =  useGetDocumentTypesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>> = ({ signal }) => getDocumentTypes(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>>
export type GetDocumentTypesQueryError = unknown

/**
 * @summary Lấy thông tin loại văn bản
 */
export const useGetDocumentTypes = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentTypesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentTypesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật loại văn bản
 */
export const useUpdateDocumentTypesHook = () => {
        const updateDocumentTypes = useCustomInstance<DocumentTypesDTO>();

        return (
    id: number,
    documentTypesDTO: DocumentTypesDTO,
 ) => {
        return updateDocumentTypes(
          {url: `/admincms/api/document-types/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: documentTypesDTO
    },
          );
        }
      }
    


export const useUpdateDocumentTypesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocumentTypes =  useUpdateDocumentTypesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentTypesHook>>>, {id: number;data: DocumentTypesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentTypes(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentTypesHook>>>>
    export type UpdateDocumentTypesMutationBody = DocumentTypesDTO
    export type UpdateDocumentTypesMutationError = unknown

    /**
 * @summary Cập nhật loại văn bản
 */
export const useUpdateDocumentTypes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentTypesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa loại văn bản
 */
export const useDeleteDocumentTypesHook = () => {
        const deleteDocumentTypes = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteDocumentTypes(
          {url: `/admincms/api/document-types/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteDocumentTypesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentTypesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentTypesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentTypes =  useDeleteDocumentTypesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentTypesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocumentTypes(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentTypesHook>>>>
    
    export type DeleteDocumentTypesMutationError = unknown

    /**
 * @summary Xóa loại văn bản
 */
export const useDeleteDocumentTypes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentTypesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteDocumentTypesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin loại văn bản
 */
export const usePartialUpdateDocumentTypesHook = () => {
        const partialUpdateDocumentTypes = useCustomInstance<DocumentTypesDTO>();

        return (
    id: number,
    documentTypesDTO: DocumentTypesDTO,
 ) => {
        return partialUpdateDocumentTypes(
          {url: `/admincms/api/document-types/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: documentTypesDTO
    },
          );
        }
      }
    


export const usePartialUpdateDocumentTypesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateDocumentTypes =  usePartialUpdateDocumentTypesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentTypesHook>>>, {id: number;data: DocumentTypesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateDocumentTypes(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateDocumentTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentTypesHook>>>>
    export type PartialUpdateDocumentTypesMutationBody = DocumentTypesDTO
    export type PartialUpdateDocumentTypesMutationError = unknown

    /**
 * @summary Cập nhật thông tin loại văn bản
 */
export const usePartialUpdateDocumentTypes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentTypesHook>>>, TError,{id: number;data: DocumentTypesDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateDocumentTypesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chi tiết đơn vị phát hành
 */
export const useGetDocumentPublishersHook = () => {
        const getDocumentPublishers = useCustomInstance<DocumentPublishersDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getDocumentPublishers(
          {url: `/admincms/api/document-publishers/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetDocumentPublishersQueryKey = (id: number,) => {
    
    return [`/admincms/api/document-publishers/${id}`] as const;
    }

    
export const useGetDocumentPublishersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentPublishersQueryKey(id);

  const getDocumentPublishers =  useGetDocumentPublishersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>> = ({ signal }) => getDocumentPublishers(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentPublishersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>>
export type GetDocumentPublishersQueryError = unknown

/**
 * @summary Chi tiết đơn vị phát hành
 */
export const useGetDocumentPublishers = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentPublishersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentPublishersQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật đơn vị phát hành
 */
export const useUpdateDocumentPublishersHook = () => {
        const updateDocumentPublishers = useCustomInstance<DocumentPublishersDTO>();

        return (
    id: number,
    documentPublishersDTO: DocumentPublishersDTO,
 ) => {
        return updateDocumentPublishers(
          {url: `/admincms/api/document-publishers/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: documentPublishersDTO
    },
          );
        }
      }
    


export const useUpdateDocumentPublishersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocumentPublishers =  useUpdateDocumentPublishersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPublishersHook>>>, {id: number;data: DocumentPublishersDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentPublishers(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentPublishersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPublishersHook>>>>
    export type UpdateDocumentPublishersMutationBody = DocumentPublishersDTO
    export type UpdateDocumentPublishersMutationError = unknown

    /**
 * @summary Cập nhật đơn vị phát hành
 */
export const useUpdateDocumentPublishers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentPublishersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa đơn vị phát hành
 */
export const useDeleteDocumentPublishersHook = () => {
        const deleteDocumentPublishers = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteDocumentPublishers(
          {url: `/admincms/api/document-publishers/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteDocumentPublishersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentPublishersHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentPublishersHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentPublishers =  useDeleteDocumentPublishersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentPublishersHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocumentPublishers(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentPublishersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentPublishersHook>>>>
    
    export type DeleteDocumentPublishersMutationError = unknown

    /**
 * @summary Xóa đơn vị phát hành
 */
export const useDeleteDocumentPublishers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentPublishersHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteDocumentPublishersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật đơn vị phát hành
 */
export const usePartialUpdateDocumentPublishersHook = () => {
        const partialUpdateDocumentPublishers = useCustomInstance<DocumentPublishersDTO>();

        return (
    id: number,
    documentPublishersDTO: DocumentPublishersDTO,
 ) => {
        return partialUpdateDocumentPublishers(
          {url: `/admincms/api/document-publishers/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: documentPublishersDTO
    },
          );
        }
      }
    


export const usePartialUpdateDocumentPublishersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateDocumentPublishers =  usePartialUpdateDocumentPublishersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentPublishersHook>>>, {id: number;data: DocumentPublishersDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateDocumentPublishers(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateDocumentPublishersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentPublishersHook>>>>
    export type PartialUpdateDocumentPublishersMutationBody = DocumentPublishersDTO
    export type PartialUpdateDocumentPublishersMutationError = unknown

    /**
 * @summary Cập nhật đơn vị phát hành
 */
export const usePartialUpdateDocumentPublishers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentPublishersHook>>>, TError,{id: number;data: DocumentPublishersDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateDocumentPublishersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy tệp văn bản cụ thể
 */
export const useGetDocumentFilesHook = () => {
        const getDocumentFiles = useCustomInstance<DocumentFilesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getDocumentFiles(
          {url: `/admincms/api/document-files/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetDocumentFilesQueryKey = (id: number,) => {
    
    return [`/admincms/api/document-files/${id}`] as const;
    }

    
export const useGetDocumentFilesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentFilesQueryKey(id);

  const getDocumentFiles =  useGetDocumentFilesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>> = ({ signal }) => getDocumentFiles(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentFilesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>>
export type GetDocumentFilesQueryError = unknown

/**
 * @summary Lấy tệp văn bản cụ thể
 */
export const useGetDocumentFiles = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFilesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentFilesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Sửa tệp văn bản cụ thể
 */
export const useUpdateDocumentFilesHook = () => {
        const updateDocumentFiles = useCustomInstance<DocumentFilesDTO>();

        return (
    id: number,
    documentFilesDTO: DocumentFilesDTO,
 ) => {
        return updateDocumentFiles(
          {url: `/admincms/api/document-files/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: documentFilesDTO
    },
          );
        }
      }
    


export const useUpdateDocumentFilesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocumentFiles =  useUpdateDocumentFilesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentFilesHook>>>, {id: number;data: DocumentFilesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentFiles(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentFilesHook>>>>
    export type UpdateDocumentFilesMutationBody = DocumentFilesDTO
    export type UpdateDocumentFilesMutationError = unknown

    /**
 * @summary Sửa tệp văn bản cụ thể
 */
export const useUpdateDocumentFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa tệp văn bản
 */
export const useDeleteDocumentFilesHook = () => {
        const deleteDocumentFiles = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteDocumentFiles(
          {url: `/admincms/api/document-files/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteDocumentFilesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFilesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFilesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentFiles =  useDeleteDocumentFilesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFilesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocumentFiles(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFilesHook>>>>
    
    export type DeleteDocumentFilesMutationError = unknown

    /**
 * @summary Xóa tệp văn bản
 */
export const useDeleteDocumentFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFilesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteDocumentFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật tệp văn bản
 */
export const usePartialUpdateDocumentFilesHook = () => {
        const partialUpdateDocumentFiles = useCustomInstance<DocumentFilesDTO>();

        return (
    id: number,
    documentFilesDTO: DocumentFilesDTO,
 ) => {
        return partialUpdateDocumentFiles(
          {url: `/admincms/api/document-files/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: documentFilesDTO
    },
          );
        }
      }
    


export const usePartialUpdateDocumentFilesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateDocumentFiles =  usePartialUpdateDocumentFilesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentFilesHook>>>, {id: number;data: DocumentFilesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateDocumentFiles(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateDocumentFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentFilesHook>>>>
    export type PartialUpdateDocumentFilesMutationBody = DocumentFilesDTO
    export type PartialUpdateDocumentFilesMutationError = unknown

    /**
 * @summary Cập nhật tệp văn bản
 */
export const usePartialUpdateDocumentFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateDocumentFilesHook>>>, TError,{id: number;data: DocumentFilesDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateDocumentFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy dữ liệu biểu đồ theo ID
 */
export const useGetChartDataLoadsHook = () => {
        const getChartDataLoads = useCustomInstance<ChartDataLoadsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getChartDataLoads(
          {url: `/admincms/api/chart-data-loads/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetChartDataLoadsQueryKey = (id: number,) => {
    
    return [`/admincms/api/chart-data-loads/${id}`] as const;
    }

    
export const useGetChartDataLoadsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChartDataLoadsQueryKey(id);

  const getChartDataLoads =  useGetChartDataLoadsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>> = ({ signal }) => getChartDataLoads(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChartDataLoadsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>>
export type GetChartDataLoadsQueryError = unknown

/**
 * @summary Lấy dữ liệu biểu đồ theo ID
 */
export const useGetChartDataLoads = <TData = Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartDataLoadsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetChartDataLoadsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật dữ liệu biểu đồ
 */
export const useUpdateChartDataLoadsHook = () => {
        const updateChartDataLoads = useCustomInstance<ChartDataLoadsDTO>();

        return (
    id: number,
    chartDataLoadsDTO: ChartDataLoadsDTO,
 ) => {
        return updateChartDataLoads(
          {url: `/admincms/api/chart-data-loads/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: chartDataLoadsDTO
    },
          );
        }
      }
    


export const useUpdateChartDataLoadsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateChartDataLoads =  useUpdateChartDataLoadsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateChartDataLoadsHook>>>, {id: number;data: ChartDataLoadsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateChartDataLoads(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateChartDataLoadsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateChartDataLoadsHook>>>>
    export type UpdateChartDataLoadsMutationBody = ChartDataLoadsDTO
    export type UpdateChartDataLoadsMutationError = unknown

    /**
 * @summary Cập nhật dữ liệu biểu đồ
 */
export const useUpdateChartDataLoads = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateChartDataLoadsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa dữ liệu biểu đồ theo ID
 */
export const useDeleteChartDataLoadsHook = () => {
        const deleteChartDataLoads = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteChartDataLoads(
          {url: `/admincms/api/chart-data-loads/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteChartDataLoadsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartDataLoadsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartDataLoadsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteChartDataLoads =  useDeleteChartDataLoadsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteChartDataLoadsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteChartDataLoads(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteChartDataLoadsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteChartDataLoadsHook>>>>
    
    export type DeleteChartDataLoadsMutationError = unknown

    /**
 * @summary Xóa dữ liệu biểu đồ theo ID
 */
export const useDeleteChartDataLoads = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartDataLoadsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteChartDataLoadsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật dữ liệu biểu đồ
 */
export const usePartialUpdateChartDataLoadsHook = () => {
        const partialUpdateChartDataLoads = useCustomInstance<ChartDataLoadsDTO>();

        return (
    id: number,
    chartDataLoadsDTO: ChartDataLoadsDTO,
 ) => {
        return partialUpdateChartDataLoads(
          {url: `/admincms/api/chart-data-loads/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: chartDataLoadsDTO
    },
          );
        }
      }
    


export const usePartialUpdateChartDataLoadsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateChartDataLoads =  usePartialUpdateChartDataLoadsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartDataLoadsHook>>>, {id: number;data: ChartDataLoadsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateChartDataLoads(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateChartDataLoadsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartDataLoadsHook>>>>
    export type PartialUpdateChartDataLoadsMutationBody = ChartDataLoadsDTO
    export type PartialUpdateChartDataLoadsMutationError = unknown

    /**
 * @summary Cập nhật dữ liệu biểu đồ
 */
export const usePartialUpdateChartDataLoads = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartDataLoadsHook>>>, TError,{id: number;data: ChartDataLoadsDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateChartDataLoadsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Thay đổi thứ tự ảnh
 */
export const useChangeBannerImageOrderHook = () => {
        const changeBannerImageOrder = useCustomInstance<void>();

        return (
    id: number,
    sortOrder: number,
    changeBannerImageOrderBody: number,
 ) => {
        return changeBannerImageOrder(
          {url: `/admincms/api/banners/${id}/images/${sortOrder}/order`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: changeBannerImageOrderBody
    },
          );
        }
      }
    


export const useChangeBannerImageOrderMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBannerImageOrderHook>>>, TError,{id: number;sortOrder: number;data: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBannerImageOrderHook>>>, TError,{id: number;sortOrder: number;data: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const changeBannerImageOrder =  useChangeBannerImageOrderHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeBannerImageOrderHook>>>, {id: number;sortOrder: number;data: number}> = (props) => {
          const {id,sortOrder,data} = props ?? {};

          return  changeBannerImageOrder(id,sortOrder,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ChangeBannerImageOrderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeBannerImageOrderHook>>>>
    export type ChangeBannerImageOrderMutationBody = number
    export type ChangeBannerImageOrderMutationError = unknown

    /**
 * @summary Thay đổi thứ tự ảnh
 */
export const useChangeBannerImageOrder = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBannerImageOrderHook>>>, TError,{id: number;sortOrder: number;data: number}, TContext>, }
) => {

      const mutationOptions = useChangeBannerImageOrderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách videos
 */
export const useGetAllVideosHook = () => {
        const getAllVideos = useCustomInstance<PageVideoSummary>();

        return (
    params?: GetAllVideosParams,
 signal?: AbortSignal
) => {
        return getAllVideos(
          {url: `/admincms/api/videos`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllVideosQueryKey = (params?: GetAllVideosParams,) => {
    
    return [`/admincms/api/videos`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllVideosQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>, TError = unknown>(params?: GetAllVideosParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllVideosQueryKey(params);

  const getAllVideos =  useGetAllVideosHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>> = ({ signal }) => getAllVideos(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllVideosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>>
export type GetAllVideosQueryError = unknown

/**
 * @summary Lấy danh sách videos
 */
export const useGetAllVideos = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>, TError = unknown>(
 params?: GetAllVideosParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllVideosHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllVideosQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới videos
 */
export const useCreateVideosHook = () => {
        const createVideos = useCustomInstance<void>();

        return (
    iUVideoDTO: IUVideoDTO,
 ) => {const formData = customFormData(iUVideoDTO)
        return createVideos(
          {url: `/admincms/api/videos`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreateVideosMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVideosHook>>>, TError,{data: IUVideoDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVideosHook>>>, TError,{data: IUVideoDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createVideos =  useCreateVideosHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateVideosHook>>>, {data: IUVideoDTO}> = (props) => {
          const {data} = props ?? {};

          return  createVideos(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateVideosMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateVideosHook>>>>
    export type CreateVideosMutationBody = IUVideoDTO
    export type CreateVideosMutationError = unknown

    /**
 * @summary Thêm mới videos
 */
export const useCreateVideos = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVideosHook>>>, TError,{data: IUVideoDTO}, TContext>, }
) => {

      const mutationOptions = useCreateVideosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy giá trị SEO
 */
export const useGetSeoValuesHook = () => {
        const getSeoValues = useCustomInstance<SeoValuesDTO>();

        return (
    params: GetSeoValuesParams,
 signal?: AbortSignal
) => {
        return getSeoValues(
          {url: `/admincms/api/seo-values`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSeoValuesQueryKey = (params: GetSeoValuesParams,) => {
    
    return [`/admincms/api/seo-values`, ...(params ? [params]: [])] as const;
    }

    
export const useGetSeoValuesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>, TError = unknown>(params: GetSeoValuesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSeoValuesQueryKey(params);

  const getSeoValues =  useGetSeoValuesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>> = ({ signal }) => getSeoValues(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSeoValuesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>>
export type GetSeoValuesQueryError = unknown

/**
 * @summary Lấy giá trị SEO
 */
export const useGetSeoValues = <TData = Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>, TError = unknown>(
 params: GetSeoValuesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSeoValuesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSeoValuesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Tạo mới giá trị SEO
 */
export const useCreateSeoValuesHook = () => {
        const createSeoValues = useCustomInstance<SeoValuesDTO>();

        return (
    seoValuesDTO: SeoValuesDTO,
 ) => {const formData = customFormData(seoValuesDTO)
        return createSeoValues(
          {url: `/admincms/api/seo-values`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreateSeoValuesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateSeoValuesHook>>>, TError,{data: SeoValuesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateSeoValuesHook>>>, TError,{data: SeoValuesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createSeoValues =  useCreateSeoValuesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateSeoValuesHook>>>, {data: SeoValuesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createSeoValues(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateSeoValuesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateSeoValuesHook>>>>
    export type CreateSeoValuesMutationBody = SeoValuesDTO
    export type CreateSeoValuesMutationError = unknown

    /**
 * @summary Tạo mới giá trị SEO
 */
export const useCreateSeoValues = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateSeoValuesHook>>>, TError,{data: SeoValuesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateSeoValuesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Lấy danh sách nhóm quyền
 * @summary Lấy danh sách nhóm quyền
 */
export const useGetAllRolesHook = () => {
        const getAllRoles = useCustomInstance<PageRoleGroupSummary>();

        return (
    params?: GetAllRolesParams,
 signal?: AbortSignal
) => {
        return getAllRoles(
          {url: `/admincms/api/role-groups`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllRolesQueryKey = (params?: GetAllRolesParams,) => {
    
    return [`/admincms/api/role-groups`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>, TError = unknown>(params?: GetAllRolesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllRolesQueryKey(params);

  const getAllRoles =  useGetAllRolesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>> = ({ signal }) => getAllRoles(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>>
export type GetAllRolesQueryError = unknown

/**
 * @summary Lấy danh sách nhóm quyền
 */
export const useGetAllRoles = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>, TError = unknown>(
 params?: GetAllRolesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllRolesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllRolesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Thêm mới nhóm quyền
 * @summary Thêm mới nhóm quyền
 */
export const useCreateRolesHook = () => {
        const createRoles = useCustomInstance<void>();

        return (
    iURoleGroupDTO: IURoleGroupDTO,
 ) => {
        return createRoles(
          {url: `/admincms/api/role-groups`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: iURoleGroupDTO
    },
          );
        }
      }
    


export const useCreateRolesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateRolesHook>>>, TError,{data: IURoleGroupDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateRolesHook>>>, TError,{data: IURoleGroupDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createRoles =  useCreateRolesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateRolesHook>>>, {data: IURoleGroupDTO}> = (props) => {
          const {data} = props ?? {};

          return  createRoles(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateRolesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateRolesHook>>>>
    export type CreateRolesMutationBody = IURoleGroupDTO
    export type CreateRolesMutationError = unknown

    /**
 * @summary Thêm mới nhóm quyền
 */
export const useCreateRoles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateRolesHook>>>, TError,{data: IURoleGroupDTO}, TContext>, }
) => {

      const mutationOptions = useCreateRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách bài viết
 */
export const useGetAllPostsHook = () => {
        const getAllPosts = useCustomInstance<PagePostsDTO>();

        return (
    params?: GetAllPostsParams,
 signal?: AbortSignal
) => {
        return getAllPosts(
          {url: `/admincms/api/posts`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllPostsQueryKey = (params?: GetAllPostsParams,) => {
    
    return [`/admincms/api/posts`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllPostsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>, TError = unknown>(params?: GetAllPostsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPostsQueryKey(params);

  const getAllPosts =  useGetAllPostsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>> = ({ signal }) => getAllPosts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPostsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>>
export type GetAllPostsQueryError = unknown

/**
 * @summary Lấy danh sách bài viết
 */
export const useGetAllPosts = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>, TError = unknown>(
 params?: GetAllPostsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllPostsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới bài viết
 */
export const useCreatePostsHook = () => {
        const createPosts = useCustomInstance<Posts>();

        return (
    iUPostDTO: IUPostDTO,
 ) => {const formData = customFormData(iUPostDTO)
        return createPosts(
          {url: `/admincms/api/posts`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreatePostsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostsHook>>>, TError,{data: IUPostDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostsHook>>>, TError,{data: IUPostDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createPosts =  useCreatePostsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreatePostsHook>>>, {data: IUPostDTO}> = (props) => {
          const {data} = props ?? {};

          return  createPosts(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePostsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreatePostsHook>>>>
    export type CreatePostsMutationBody = IUPostDTO
    export type CreatePostsMutationError = unknown

    /**
 * @summary Thêm mới bài viết
 */
export const useCreatePosts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostsHook>>>, TError,{data: IUPostDTO}, TContext>, }
) => {

      const mutationOptions = useCreatePostsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Thêm mới ảnh nội dung bài viết
 */
export const useUploadImageHook = () => {
        const uploadImage = useCustomInstance<string>();

        return (
    uploadImageBody: UploadImageBody,
 ) => {const formData = customFormData(uploadImageBody)
        return uploadImage(
          {url: `/admincms/api/posts/content-image`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUploadImageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImageHook>>>, TError,{data: UploadImageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImageHook>>>, TError,{data: UploadImageBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const uploadImage =  useUploadImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadImageHook>>>, {data: UploadImageBody}> = (props) => {
          const {data} = props ?? {};

          return  uploadImage(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadImageHook>>>>
    export type UploadImageMutationBody = UploadImageBody
    export type UploadImageMutationError = unknown

    /**
 * @summary Thêm mới ảnh nội dung bài viết
 */
export const useUploadImage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImageHook>>>, TError,{data: UploadImageBody}, TContext>, }
) => {

      const mutationOptions = useUploadImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách danh mục bài viết
 */
export const useGetAllPostCategoriesHook = () => {
        const getAllPostCategories = useCustomInstance<PostCategoriesHierarchy[]>();

        return (
    params: GetAllPostCategoriesParams,
 signal?: AbortSignal
) => {
        return getAllPostCategories(
          {url: `/admincms/api/post-categories`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllPostCategoriesQueryKey = (params: GetAllPostCategoriesParams,) => {
    
    return [`/admincms/api/post-categories`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllPostCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>, TError = unknown>(params: GetAllPostCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPostCategoriesQueryKey(params);

  const getAllPostCategories =  useGetAllPostCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>> = ({ signal }) => getAllPostCategories(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPostCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>>
export type GetAllPostCategoriesQueryError = unknown

/**
 * @summary Lấy danh sách danh mục bài viết
 */
export const useGetAllPostCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>, TError = unknown>(
 params: GetAllPostCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllPostCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Tạo mới danh mục bài viết
 */
export const useCreatePostCategoriesHook = () => {
        const createPostCategories = useCustomInstance<void>();

        return (
    iUPostCategoriesDTO: IUPostCategoriesDTO,
 ) => {
        return createPostCategories(
          {url: `/admincms/api/post-categories`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: iUPostCategoriesDTO
    },
          );
        }
      }
    


export const useCreatePostCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostCategoriesHook>>>, TError,{data: IUPostCategoriesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostCategoriesHook>>>, TError,{data: IUPostCategoriesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createPostCategories =  useCreatePostCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreatePostCategoriesHook>>>, {data: IUPostCategoriesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createPostCategories(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePostCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreatePostCategoriesHook>>>>
    export type CreatePostCategoriesMutationBody = IUPostCategoriesDTO
    export type CreatePostCategoriesMutationError = unknown

    /**
 * @summary Tạo mới danh mục bài viết
 */
export const useCreatePostCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePostCategoriesHook>>>, TError,{data: IUPostCategoriesDTO}, TContext>, }
) => {

      const mutationOptions = useCreatePostCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách trang
 */
export const useGetAllPagesHook = () => {
        const getAllPages = useCustomInstance<PagePagesDTO>();

        return (
    params?: GetAllPagesParams,
 signal?: AbortSignal
) => {
        return getAllPages(
          {url: `/admincms/api/pages`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllPagesQueryKey = (params?: GetAllPagesParams,) => {
    
    return [`/admincms/api/pages`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllPagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError = unknown>(params?: GetAllPagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPagesQueryKey(params);

  const getAllPages =  useGetAllPagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>> = ({ signal }) => getAllPages(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>>
export type GetAllPagesQueryError = unknown

/**
 * @summary Lấy danh sách trang
 */
export const useGetAllPages = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError = unknown>(
 params?: GetAllPagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllPagesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Tạo mới trang
 */
export const useCreatePagesHook = () => {
        const createPages = useCustomInstance<PagesDTO>();

        return (
    pagesDTO: PagesDTO,
 ) => {
        return createPages(
          {url: `/admincms/api/pages`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: pagesDTO
    },
          );
        }
      }
    


export const useCreatePagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePagesHook>>>, TError,{data: PagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePagesHook>>>, TError,{data: PagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createPages =  useCreatePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreatePagesHook>>>, {data: PagesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createPages(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreatePagesHook>>>>
    export type CreatePagesMutationBody = PagesDTO
    export type CreatePagesMutationError = unknown

    /**
 * @summary Tạo mới trang
 */
export const useCreatePages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreatePagesHook>>>, TError,{data: PagesDTO}, TContext>, }
) => {

      const mutationOptions = useCreatePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Thêm mới ảnh cho trang
 */
export const useUploadImage1Hook = () => {
        const uploadImage1 = useCustomInstance<string>();

        return (
    uploadImage1Body: UploadImage1Body,
 ) => {const formData = customFormData(uploadImage1Body)
        return uploadImage1(
          {url: `/admincms/api/pages/content-image`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUploadImage1MutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImage1Hook>>>, TError,{data: UploadImage1Body}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImage1Hook>>>, TError,{data: UploadImage1Body}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const uploadImage1 =  useUploadImage1Hook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadImage1Hook>>>, {data: UploadImage1Body}> = (props) => {
          const {data} = props ?? {};

          return  uploadImage1(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadImage1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadImage1Hook>>>>
    export type UploadImage1MutationBody = UploadImage1Body
    export type UploadImage1MutationError = unknown

    /**
 * @summary Thêm mới ảnh cho trang
 */
export const useUploadImage1 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadImage1Hook>>>, TError,{data: UploadImage1Body}, TContext>, }
) => {

      const mutationOptions = useUploadImage1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách điều hướng
 */
export const useGetAllNavigationsHook = () => {
        const getAllNavigations = useCustomInstance<PageNavigationsDTO>();

        return (
    params?: GetAllNavigationsParams,
 signal?: AbortSignal
) => {
        return getAllNavigations(
          {url: `/admincms/api/navigations`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllNavigationsQueryKey = (params?: GetAllNavigationsParams,) => {
    
    return [`/admincms/api/navigations`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllNavigationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>, TError = unknown>(params?: GetAllNavigationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllNavigationsQueryKey(params);

  const getAllNavigations =  useGetAllNavigationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>> = ({ signal }) => getAllNavigations(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllNavigationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>>
export type GetAllNavigationsQueryError = unknown

/**
 * @summary Lấy danh sách điều hướng
 */
export const useGetAllNavigations = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>, TError = unknown>(
 params?: GetAllNavigationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllNavigationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới điều hướng
 */
export const useCreateNavigationsHook = () => {
        const createNavigations = useCustomInstance<NavigationsDTO>();

        return (
    navigationsDTO: NavigationsDTO,
 ) => {
        return createNavigations(
          {url: `/admincms/api/navigations`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: navigationsDTO
    },
          );
        }
      }
    


export const useCreateNavigationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationsHook>>>, TError,{data: NavigationsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationsHook>>>, TError,{data: NavigationsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createNavigations =  useCreateNavigationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateNavigationsHook>>>, {data: NavigationsDTO}> = (props) => {
          const {data} = props ?? {};

          return  createNavigations(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNavigationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateNavigationsHook>>>>
    export type CreateNavigationsMutationBody = NavigationsDTO
    export type CreateNavigationsMutationError = unknown

    /**
 * @summary Thêm mới điều hướng
 */
export const useCreateNavigations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationsHook>>>, TError,{data: NavigationsDTO}, TContext>, }
) => {

      const mutationOptions = useCreateNavigationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách liên kết
 */
export const useGetAllNavigationLinksHook = () => {
        const getAllNavigationLinks = useCustomInstance<NavigationLinksHierarchy[]>();

        return (
    params?: GetAllNavigationLinksParams,
 signal?: AbortSignal
) => {
        return getAllNavigationLinks(
          {url: `/admincms/api/navigation-links`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllNavigationLinksQueryKey = (params?: GetAllNavigationLinksParams,) => {
    
    return [`/admincms/api/navigation-links`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllNavigationLinksQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>, TError = unknown>(params?: GetAllNavigationLinksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllNavigationLinksQueryKey(params);

  const getAllNavigationLinks =  useGetAllNavigationLinksHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>> = ({ signal }) => getAllNavigationLinks(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllNavigationLinksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>>
export type GetAllNavigationLinksQueryError = unknown

/**
 * @summary Lấy danh sách liên kết
 */
export const useGetAllNavigationLinks = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>, TError = unknown>(
 params?: GetAllNavigationLinksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllNavigationLinksHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllNavigationLinksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới liên kết
 */
export const useCreateNavigationLinksHook = () => {
        const createNavigationLinks = useCustomInstance<NavigationLinksDTO>();

        return (
    iUNavigationLinksDTO: IUNavigationLinksDTO,
 ) => {const formData = customFormData(iUNavigationLinksDTO)
        return createNavigationLinks(
          {url: `/admincms/api/navigation-links`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreateNavigationLinksMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationLinksHook>>>, TError,{data: IUNavigationLinksDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationLinksHook>>>, TError,{data: IUNavigationLinksDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createNavigationLinks =  useCreateNavigationLinksHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateNavigationLinksHook>>>, {data: IUNavigationLinksDTO}> = (props) => {
          const {data} = props ?? {};

          return  createNavigationLinks(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNavigationLinksMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateNavigationLinksHook>>>>
    export type CreateNavigationLinksMutationBody = IUNavigationLinksDTO
    export type CreateNavigationLinksMutationError = unknown

    /**
 * @summary Thêm mới liên kết
 */
export const useCreateNavigationLinks = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateNavigationLinksHook>>>, TError,{data: IUNavigationLinksDTO}, TContext>, }
) => {

      const mutationOptions = useCreateNavigationLinksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách ngôn ngữ
 */
export const useGetAllLanguagesHook = () => {
        const getAllLanguages = useCustomInstance<PageLanguagesDTO>();

        return (
    params?: GetAllLanguagesParams,
 signal?: AbortSignal
) => {
        return getAllLanguages(
          {url: `/admincms/api/languages`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllLanguagesQueryKey = (params?: GetAllLanguagesParams,) => {
    
    return [`/admincms/api/languages`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllLanguagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>, TError = unknown>(params?: GetAllLanguagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllLanguagesQueryKey(params);

  const getAllLanguages =  useGetAllLanguagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>> = ({ signal }) => getAllLanguages(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllLanguagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>>
export type GetAllLanguagesQueryError = unknown

/**
 * @summary Lấy danh sách ngôn ngữ
 */
export const useGetAllLanguages = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>, TError = unknown>(
 params?: GetAllLanguagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLanguagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllLanguagesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới ngôn ngữ
 */
export const useCreateLanguagesHook = () => {
        const createLanguages = useCustomInstance<LanguagesDTO>();

        return (
    languagesDTO: LanguagesDTO,
 ) => {
        return createLanguages(
          {url: `/admincms/api/languages`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: languagesDTO
    },
          );
        }
      }
    


export const useCreateLanguagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLanguagesHook>>>, TError,{data: LanguagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLanguagesHook>>>, TError,{data: LanguagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createLanguages =  useCreateLanguagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateLanguagesHook>>>, {data: LanguagesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createLanguages(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateLanguagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateLanguagesHook>>>>
    export type CreateLanguagesMutationBody = LanguagesDTO
    export type CreateLanguagesMutationError = unknown

    /**
 * @summary Thêm mới ngôn ngữ
 */
export const useCreateLanguages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLanguagesHook>>>, TError,{data: LanguagesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateLanguagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách danh mục câu hỏi thường gặp
 */
export const useGetAllFaqCategoriesHook = () => {
        const getAllFaqCategories = useCustomInstance<PageFaqCategoriesDTO>();

        return (
    params?: GetAllFaqCategoriesParams,
 signal?: AbortSignal
) => {
        return getAllFaqCategories(
          {url: `/admincms/api/faq-categories`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllFaqCategoriesQueryKey = (params?: GetAllFaqCategoriesParams,) => {
    
    return [`/admincms/api/faq-categories`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllFaqCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>, TError = unknown>(params?: GetAllFaqCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllFaqCategoriesQueryKey(params);

  const getAllFaqCategories =  useGetAllFaqCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>> = ({ signal }) => getAllFaqCategories(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllFaqCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>>
export type GetAllFaqCategoriesQueryError = unknown

/**
 * @summary Lấy danh sách danh mục câu hỏi thường gặp
 */
export const useGetAllFaqCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>, TError = unknown>(
 params?: GetAllFaqCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllFaqCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Tạo mới danh mục câu hỏi thường gặp
 */
export const useCreateFaqCategoriesHook = () => {
        const createFaqCategories = useCustomInstance<FaqCategoriesDTO>();

        return (
    faqCategoriesDTO: FaqCategoriesDTO,
 ) => {
        return createFaqCategories(
          {url: `/admincms/api/faq-categories`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: faqCategoriesDTO
    },
          );
        }
      }
    


export const useCreateFaqCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFaqCategoriesHook>>>, TError,{data: FaqCategoriesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFaqCategoriesHook>>>, TError,{data: FaqCategoriesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createFaqCategories =  useCreateFaqCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateFaqCategoriesHook>>>, {data: FaqCategoriesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createFaqCategories(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateFaqCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateFaqCategoriesHook>>>>
    export type CreateFaqCategoriesMutationBody = FaqCategoriesDTO
    export type CreateFaqCategoriesMutationError = unknown

    /**
 * @summary Tạo mới danh mục câu hỏi thường gặp
 */
export const useCreateFaqCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFaqCategoriesHook>>>, TError,{data: FaqCategoriesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateFaqCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách văn bản
 */
export const useGetAllDocumentsHook = () => {
        const getAllDocuments = useCustomInstance<PageDocumentsDTO>();

        return (
    params?: GetAllDocumentsParams,
 signal?: AbortSignal
) => {
        return getAllDocuments(
          {url: `/admincms/api/documents`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllDocumentsQueryKey = (params?: GetAllDocumentsParams,) => {
    
    return [`/admincms/api/documents`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllDocumentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>, TError = unknown>(params?: GetAllDocumentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDocumentsQueryKey(params);

  const getAllDocuments =  useGetAllDocumentsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>> = ({ signal }) => getAllDocuments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllDocumentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>>
export type GetAllDocumentsQueryError = unknown

/**
 * @summary Lấy danh sách văn bản
 */
export const useGetAllDocuments = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>, TError = unknown>(
 params?: GetAllDocumentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllDocumentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới văn bản
 */
export const useCreateDocumentsHook = () => {
        const createDocuments = useCustomInstance<DocumentsDTO>();

        return (
    iUDocumentsDTO: IUDocumentsDTO,
 ) => {const formData = customFormData(iUDocumentsDTO)
        return createDocuments(
          {url: `/admincms/api/documents`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreateDocumentsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentsHook>>>, TError,{data: IUDocumentsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentsHook>>>, TError,{data: IUDocumentsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createDocuments =  useCreateDocumentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateDocumentsHook>>>, {data: IUDocumentsDTO}> = (props) => {
          const {data} = props ?? {};

          return  createDocuments(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateDocumentsHook>>>>
    export type CreateDocumentsMutationBody = IUDocumentsDTO
    export type CreateDocumentsMutationError = unknown

    /**
 * @summary Thêm mới văn bản
 */
export const useCreateDocuments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentsHook>>>, TError,{data: IUDocumentsDTO}, TContext>, }
) => {

      const mutationOptions = useCreateDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách loại văn bản
 */
export const useGetAllDocumentTypesHook = () => {
        const getAllDocumentTypes = useCustomInstance<PageDocumentTypesDTO>();

        return (
    params?: GetAllDocumentTypesParams,
 signal?: AbortSignal
) => {
        return getAllDocumentTypes(
          {url: `/admincms/api/document-types`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllDocumentTypesQueryKey = (params?: GetAllDocumentTypesParams,) => {
    
    return [`/admincms/api/document-types`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllDocumentTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>, TError = unknown>(params?: GetAllDocumentTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDocumentTypesQueryKey(params);

  const getAllDocumentTypes =  useGetAllDocumentTypesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>> = ({ signal }) => getAllDocumentTypes(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllDocumentTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>>
export type GetAllDocumentTypesQueryError = unknown

/**
 * @summary Lấy danh sách loại văn bản
 */
export const useGetAllDocumentTypes = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>, TError = unknown>(
 params?: GetAllDocumentTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentTypesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllDocumentTypesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới loại văn bản
 */
export const useCreateDocumentTypesHook = () => {
        const createDocumentTypes = useCustomInstance<DocumentTypesDTO>();

        return (
    documentTypesDTO: DocumentTypesDTO,
 ) => {
        return createDocumentTypes(
          {url: `/admincms/api/document-types`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: documentTypesDTO
    },
          );
        }
      }
    


export const useCreateDocumentTypesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentTypesHook>>>, TError,{data: DocumentTypesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentTypesHook>>>, TError,{data: DocumentTypesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createDocumentTypes =  useCreateDocumentTypesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateDocumentTypesHook>>>, {data: DocumentTypesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createDocumentTypes(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateDocumentTypesHook>>>>
    export type CreateDocumentTypesMutationBody = DocumentTypesDTO
    export type CreateDocumentTypesMutationError = unknown

    /**
 * @summary Thêm mới loại văn bản
 */
export const useCreateDocumentTypes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentTypesHook>>>, TError,{data: DocumentTypesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateDocumentTypesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách đơn vị phát hành
 */
export const useGetAllDocumentPublishersHook = () => {
        const getAllDocumentPublishers = useCustomInstance<PageDocumentPublishersDTO>();

        return (
    params?: GetAllDocumentPublishersParams,
 signal?: AbortSignal
) => {
        return getAllDocumentPublishers(
          {url: `/admincms/api/document-publishers`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllDocumentPublishersQueryKey = (params?: GetAllDocumentPublishersParams,) => {
    
    return [`/admincms/api/document-publishers`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllDocumentPublishersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>, TError = unknown>(params?: GetAllDocumentPublishersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDocumentPublishersQueryKey(params);

  const getAllDocumentPublishers =  useGetAllDocumentPublishersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>> = ({ signal }) => getAllDocumentPublishers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllDocumentPublishersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>>
export type GetAllDocumentPublishersQueryError = unknown

/**
 * @summary Lấy danh sách đơn vị phát hành
 */
export const useGetAllDocumentPublishers = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>, TError = unknown>(
 params?: GetAllDocumentPublishersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentPublishersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllDocumentPublishersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới đơn vị phát hành
 */
export const useCreateDocumentPublishersHook = () => {
        const createDocumentPublishers = useCustomInstance<DocumentPublishersDTO>();

        return (
    documentPublishersDTO: DocumentPublishersDTO,
 ) => {
        return createDocumentPublishers(
          {url: `/admincms/api/document-publishers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: documentPublishersDTO
    },
          );
        }
      }
    


export const useCreateDocumentPublishersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentPublishersHook>>>, TError,{data: DocumentPublishersDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentPublishersHook>>>, TError,{data: DocumentPublishersDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createDocumentPublishers =  useCreateDocumentPublishersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateDocumentPublishersHook>>>, {data: DocumentPublishersDTO}> = (props) => {
          const {data} = props ?? {};

          return  createDocumentPublishers(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentPublishersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateDocumentPublishersHook>>>>
    export type CreateDocumentPublishersMutationBody = DocumentPublishersDTO
    export type CreateDocumentPublishersMutationError = unknown

    /**
 * @summary Thêm mới đơn vị phát hành
 */
export const useCreateDocumentPublishers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentPublishersHook>>>, TError,{data: DocumentPublishersDTO}, TContext>, }
) => {

      const mutationOptions = useCreateDocumentPublishersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy ra tất cả tệp văn bản
 */
export const useGetAllDocumentFilesHook = () => {
        const getAllDocumentFiles = useCustomInstance<DocumentFilesDTO[]>();

        return (
    params?: GetAllDocumentFilesParams,
 signal?: AbortSignal
) => {
        return getAllDocumentFiles(
          {url: `/admincms/api/document-files`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllDocumentFilesQueryKey = (params?: GetAllDocumentFilesParams,) => {
    
    return [`/admincms/api/document-files`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllDocumentFilesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>, TError = unknown>(params?: GetAllDocumentFilesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDocumentFilesQueryKey(params);

  const getAllDocumentFiles =  useGetAllDocumentFilesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>> = ({ signal }) => getAllDocumentFiles(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllDocumentFilesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>>
export type GetAllDocumentFilesQueryError = unknown

/**
 * @summary Lấy ra tất cả tệp văn bản
 */
export const useGetAllDocumentFiles = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>, TError = unknown>(
 params?: GetAllDocumentFilesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentFilesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllDocumentFilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới tệp văn bản
 */
export const useCreateDocumentFilesHook = () => {
        const createDocumentFiles = useCustomInstance<DocumentFilesDTO>();

        return (
    documentFilesDTO: DocumentFilesDTO,
 ) => {
        return createDocumentFiles(
          {url: `/admincms/api/document-files`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: documentFilesDTO
    },
          );
        }
      }
    


export const useCreateDocumentFilesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentFilesHook>>>, TError,{data: DocumentFilesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentFilesHook>>>, TError,{data: DocumentFilesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createDocumentFiles =  useCreateDocumentFilesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateDocumentFilesHook>>>, {data: DocumentFilesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createDocumentFiles(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateDocumentFilesHook>>>>
    export type CreateDocumentFilesMutationBody = DocumentFilesDTO
    export type CreateDocumentFilesMutationError = unknown

    /**
 * @summary Thêm mới tệp văn bản
 */
export const useCreateDocumentFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentFilesHook>>>, TError,{data: DocumentFilesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateDocumentFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách danh mục văn bản
 */
export const useGetAllDocumentCategoriesHook = () => {
        const getAllDocumentCategories = useCustomInstance<DocumentCategoriesHierarchy[]>();

        return (
    params?: GetAllDocumentCategoriesParams,
 signal?: AbortSignal
) => {
        return getAllDocumentCategories(
          {url: `/admincms/api/document-categories`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllDocumentCategoriesQueryKey = (params?: GetAllDocumentCategoriesParams,) => {
    
    return [`/admincms/api/document-categories`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllDocumentCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>, TError = unknown>(params?: GetAllDocumentCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDocumentCategoriesQueryKey(params);

  const getAllDocumentCategories =  useGetAllDocumentCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>> = ({ signal }) => getAllDocumentCategories(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllDocumentCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>>
export type GetAllDocumentCategoriesQueryError = unknown

/**
 * @summary Lấy danh sách danh mục văn bản
 */
export const useGetAllDocumentCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>, TError = unknown>(
 params?: GetAllDocumentCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllDocumentCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllDocumentCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới danh mục văn bản
 */
export const useCreateDocumentCategoriesHook = () => {
        const createDocumentCategories = useCustomInstance<DocumentCategoriesDTO>();

        return (
    iUDocumentCategoriesDTO: IUDocumentCategoriesDTO,
 ) => {
        return createDocumentCategories(
          {url: `/admincms/api/document-categories`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: iUDocumentCategoriesDTO
    },
          );
        }
      }
    


export const useCreateDocumentCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentCategoriesHook>>>, TError,{data: IUDocumentCategoriesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentCategoriesHook>>>, TError,{data: IUDocumentCategoriesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createDocumentCategories =  useCreateDocumentCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateDocumentCategoriesHook>>>, {data: IUDocumentCategoriesDTO}> = (props) => {
          const {data} = props ?? {};

          return  createDocumentCategories(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateDocumentCategoriesHook>>>>
    export type CreateDocumentCategoriesMutationBody = IUDocumentCategoriesDTO
    export type CreateDocumentCategoriesMutationError = unknown

    /**
 * @summary Thêm mới danh mục văn bản
 */
export const useCreateDocumentCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateDocumentCategoriesHook>>>, TError,{data: IUDocumentCategoriesDTO}, TContext>, }
) => {

      const mutationOptions = useCreateDocumentCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Lấy danh sách biểu đồ
 * @summary Lấy danh sách biểu đồ
 */
export const useGetAllChartsHook = () => {
        const getAllCharts = useCustomInstance<PageChartsDTO>();

        return (
    params: GetAllChartsParams,
 signal?: AbortSignal
) => {
        return getAllCharts(
          {url: `/admincms/api/charts`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllChartsQueryKey = (params: GetAllChartsParams,) => {
    
    return [`/admincms/api/charts`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllChartsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>, TError = unknown>(params: GetAllChartsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllChartsQueryKey(params);

  const getAllCharts =  useGetAllChartsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>> = ({ signal }) => getAllCharts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllChartsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>>
export type GetAllChartsQueryError = unknown

/**
 * @summary Lấy danh sách biểu đồ
 */
export const useGetAllCharts = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>, TError = unknown>(
 params: GetAllChartsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllChartsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Thêm mới biểu đồ
 * @summary Thêm mới biểu đồ
 */
export const useCreateChartsHook = () => {
        const createCharts = useCustomInstance<void>();

        return (
    iUChartDTO: IUChartDTO,
 ) => {
        return createCharts(
          {url: `/admincms/api/charts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: iUChartDTO
    },
          );
        }
      }
    


export const useCreateChartsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartsHook>>>, TError,{data: IUChartDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartsHook>>>, TError,{data: IUChartDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createCharts =  useCreateChartsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateChartsHook>>>, {data: IUChartDTO}> = (props) => {
          const {data} = props ?? {};

          return  createCharts(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateChartsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateChartsHook>>>>
    export type CreateChartsMutationBody = IUChartDTO
    export type CreateChartsMutationError = unknown

    /**
 * @summary Thêm mới biểu đồ
 */
export const useCreateCharts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartsHook>>>, TError,{data: IUChartDTO}, TContext>, }
) => {

      const mutationOptions = useCreateChartsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách dữ liệu biểu đồ
 */
export const useGetAllChartDataLoadsHook = () => {
        const getAllChartDataLoads = useCustomInstance<ChartDataLoadsDTO[]>();

        return (
    params?: GetAllChartDataLoadsParams,
 signal?: AbortSignal
) => {
        return getAllChartDataLoads(
          {url: `/admincms/api/chart-data-loads`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllChartDataLoadsQueryKey = (params?: GetAllChartDataLoadsParams,) => {
    
    return [`/admincms/api/chart-data-loads`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllChartDataLoadsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>, TError = unknown>(params?: GetAllChartDataLoadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllChartDataLoadsQueryKey(params);

  const getAllChartDataLoads =  useGetAllChartDataLoadsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>> = ({ signal }) => getAllChartDataLoads(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllChartDataLoadsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>>
export type GetAllChartDataLoadsQueryError = unknown

/**
 * @summary Lấy danh sách dữ liệu biểu đồ
 */
export const useGetAllChartDataLoads = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>, TError = unknown>(
 params?: GetAllChartDataLoadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllChartDataLoadsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllChartDataLoadsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới dữ liệu biểu đồ
 */
export const useCreateChartDataLoadsHook = () => {
        const createChartDataLoads = useCustomInstance<ChartDataLoadsDTO>();

        return (
    chartDataLoadsDTO: ChartDataLoadsDTO,
 ) => {
        return createChartDataLoads(
          {url: `/admincms/api/chart-data-loads`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chartDataLoadsDTO
    },
          );
        }
      }
    


export const useCreateChartDataLoadsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartDataLoadsHook>>>, TError,{data: ChartDataLoadsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartDataLoadsHook>>>, TError,{data: ChartDataLoadsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createChartDataLoads =  useCreateChartDataLoadsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateChartDataLoadsHook>>>, {data: ChartDataLoadsDTO}> = (props) => {
          const {data} = props ?? {};

          return  createChartDataLoads(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateChartDataLoadsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateChartDataLoadsHook>>>>
    export type CreateChartDataLoadsMutationBody = ChartDataLoadsDTO
    export type CreateChartDataLoadsMutationError = unknown

    /**
 * @summary Thêm mới dữ liệu biểu đồ
 */
export const useCreateChartDataLoads = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateChartDataLoadsHook>>>, TError,{data: ChartDataLoadsDTO}, TContext>, }
) => {

      const mutationOptions = useCreateChartDataLoadsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách banner
 */
export const useGetAllBannersHook = () => {
        const getAllBanners = useCustomInstance<PageBannerSummary>();

        return (
    params: GetAllBannersParams,
 signal?: AbortSignal
) => {
        return getAllBanners(
          {url: `/admincms/api/banners`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllBannersQueryKey = (params: GetAllBannersParams,) => {
    
    return [`/admincms/api/banners`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllBannersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>, TError = unknown>(params: GetAllBannersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllBannersQueryKey(params);

  const getAllBanners =  useGetAllBannersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>> = ({ signal }) => getAllBanners(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllBannersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>>
export type GetAllBannersQueryError = unknown

/**
 * @summary Lấy danh sách banner
 */
export const useGetAllBanners = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>, TError = unknown>(
 params: GetAllBannersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllBannersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllBannersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới banner
 */
export const useCreateBannersHook = () => {
        const createBanners = useCustomInstance<void>();

        return (
    addBannerDTO: AddBannerDTO,
 ) => {const formData = customFormData(addBannerDTO)
        return createBanners(
          {url: `/admincms/api/banners`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useCreateBannersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBannersHook>>>, TError,{data: AddBannerDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBannersHook>>>, TError,{data: AddBannerDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createBanners =  useCreateBannersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateBannersHook>>>, {data: AddBannerDTO}> = (props) => {
          const {data} = props ?? {};

          return  createBanners(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateBannersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateBannersHook>>>>
    export type CreateBannersMutationBody = AddBannerDTO
    export type CreateBannersMutationError = unknown

    /**
 * @summary Thêm mới banner
 */
export const useCreateBanners = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBannersHook>>>, TError,{data: AddBannerDTO}, TContext>, }
) => {

      const mutationOptions = useCreateBannersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Thêm mới ảnh banner
 */
export const useAddBannerImagesHook = () => {
        const addBannerImages = useCustomInstance<void>();

        return (
    id: number,
    addBannerImageDTO: AddBannerImageDTO,
 ) => {const formData = customFormData(addBannerImageDTO)
        return addBannerImages(
          {url: `/admincms/api/banners/${id}/images`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useAddBannerImagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddBannerImagesHook>>>, TError,{id: number;data: AddBannerImageDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddBannerImagesHook>>>, TError,{id: number;data: AddBannerImageDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const addBannerImages =  useAddBannerImagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddBannerImagesHook>>>, {id: number;data: AddBannerImageDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  addBannerImages(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddBannerImagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddBannerImagesHook>>>>
    export type AddBannerImagesMutationBody = AddBannerImageDTO
    export type AddBannerImagesMutationError = unknown

    /**
 * @summary Thêm mới ảnh banner
 */
export const useAddBannerImages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddBannerImagesHook>>>, TError,{id: number;data: AddBannerImageDTO}, TContext>, }
) => {

      const mutationOptions = useAddBannerImagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách album
 */
export const useGetAllAlbumsHook = () => {
        const getAllAlbums = useCustomInstance<PageAlbumsDTO>();

        return (
    params?: GetAllAlbumsParams,
 signal?: AbortSignal
) => {
        return getAllAlbums(
          {url: `/admincms/api/albums`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllAlbumsQueryKey = (params?: GetAllAlbumsParams,) => {
    
    return [`/admincms/api/albums`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllAlbumsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>, TError = unknown>(params?: GetAllAlbumsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllAlbumsQueryKey(params);

  const getAllAlbums =  useGetAllAlbumsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>> = ({ signal }) => getAllAlbums(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllAlbumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>>
export type GetAllAlbumsQueryError = unknown

/**
 * @summary Lấy danh sách album
 */
export const useGetAllAlbums = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>, TError = unknown>(
 params?: GetAllAlbumsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAlbumsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllAlbumsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới album
 */
export const useCreateAlbumsHook = () => {
        const createAlbums = useCustomInstance<void>();

        return (
    iUAlbumDTO: IUAlbumDTO,
 ) => {
        return createAlbums(
          {url: `/admincms/api/albums`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: iUAlbumDTO
    },
          );
        }
      }
    


export const useCreateAlbumsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAlbumsHook>>>, TError,{data: IUAlbumDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAlbumsHook>>>, TError,{data: IUAlbumDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createAlbums =  useCreateAlbumsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateAlbumsHook>>>, {data: IUAlbumDTO}> = (props) => {
          const {data} = props ?? {};

          return  createAlbums(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAlbumsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateAlbumsHook>>>>
    export type CreateAlbumsMutationBody = IUAlbumDTO
    export type CreateAlbumsMutationError = unknown

    /**
 * @summary Thêm mới album
 */
export const useCreateAlbums = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAlbumsHook>>>, TError,{data: IUAlbumDTO}, TContext>, }
) => {

      const mutationOptions = useCreateAlbumsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách ảnh trong album
 */
export const useGetAllImagesHook = () => {
        const getAllImages = useCustomInstance<PageAlbumImageSummary>();

        return (
    id: number,
    params?: GetAllImagesParams,
 signal?: AbortSignal
) => {
        return getAllImages(
          {url: `/admincms/api/albums/${id}/images`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllImagesQueryKey = (id: number,
    params?: GetAllImagesParams,) => {
    
    return [`/admincms/api/albums/${id}/images`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllImagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>, TError = unknown>(id: number,
    params?: GetAllImagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllImagesQueryKey(id,params);

  const getAllImages =  useGetAllImagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>> = ({ signal }) => getAllImages(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllImagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>>
export type GetAllImagesQueryError = unknown

/**
 * @summary Lấy danh sách ảnh trong album
 */
export const useGetAllImages = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>, TError = unknown>(
 id: number,
    params?: GetAllImagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllImagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllImagesQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Thêm mới ảnh vào album
 */
export const useAddImageHook = () => {
        const addImage = useCustomInstance<void>();

        return (
    id: number,
    iUAlbumImagesDTO: IUAlbumImagesDTO,
 ) => {const formData = customFormData(iUAlbumImagesDTO)
        return addImage(
          {url: `/admincms/api/albums/${id}/images`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useAddImageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddImageHook>>>, TError,{id: number;data: IUAlbumImagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddImageHook>>>, TError,{id: number;data: IUAlbumImagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const addImage =  useAddImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddImageHook>>>, {id: number;data: IUAlbumImagesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  addImage(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddImageHook>>>>
    export type AddImageMutationBody = IUAlbumImagesDTO
    export type AddImageMutationError = unknown

    /**
 * @summary Thêm mới ảnh vào album
 */
export const useAddImage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddImageHook>>>, TError,{id: number;data: IUAlbumImagesDTO}, TContext>, }
) => {

      const mutationOptions = useAddImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật danh mục văn bản
 */
export const useUpdateDocumentCategoriesHook = () => {
        const updateDocumentCategories = useCustomInstance<void>();

        return (
    id: number,
    iUDocumentCategoriesDTO: IUDocumentCategoriesDTO,
 ) => {
        return updateDocumentCategories(
          {url: `/admincms/api/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: iUDocumentCategoriesDTO
    },
          );
        }
      }
    


export const useUpdateDocumentCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentCategoriesHook>>>, TError,{id: number;data: IUDocumentCategoriesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentCategoriesHook>>>, TError,{id: number;data: IUDocumentCategoriesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateDocumentCategories =  useUpdateDocumentCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentCategoriesHook>>>, {id: number;data: IUDocumentCategoriesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentCategories(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentCategoriesHook>>>>
    export type UpdateDocumentCategoriesMutationBody = IUDocumentCategoriesDTO
    export type UpdateDocumentCategoriesMutationError = unknown

    /**
 * @summary Cập nhật danh mục văn bản
 */
export const useUpdateDocumentCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentCategoriesHook>>>, TError,{id: number;data: IUDocumentCategoriesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateDocumentCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin videos
 */
export const useGetVideosHook = () => {
        const getVideos = useCustomInstance<VideosDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getVideos(
          {url: `/admincms/api/videos/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetVideosQueryKey = (id: number,) => {
    
    return [`/admincms/api/videos/${id}`] as const;
    }

    
export const useGetVideosQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideosQueryKey(id);

  const getVideos =  useGetVideosHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>> = ({ signal }) => getVideos(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>>
export type GetVideosQueryError = unknown

/**
 * @summary Lấy thông tin videos
 */
export const useGetVideos = <TData = Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVideosHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetVideosQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa videos
 */
export const useDeleteVideosHook = () => {
        const deleteVideos = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteVideos(
          {url: `/admincms/api/videos/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteVideosMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteVideosHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteVideosHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteVideos =  useDeleteVideosHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteVideosHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteVideos(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteVideosMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteVideosHook>>>>
    
    export type DeleteVideosMutationError = unknown

    /**
 * @summary Xóa videos
 */
export const useDeleteVideos = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteVideosHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteVideosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật videos
 */
export const usePartialUpdateVideosHook = () => {
        const partialUpdateVideos = useCustomInstance<void>();

        return (
    id: number,
    iUVideoDTO: IUVideoDTO,
 ) => {const formData = customFormData(iUVideoDTO)
        return partialUpdateVideos(
          {url: `/admincms/api/videos/${id}`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const usePartialUpdateVideosMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateVideosHook>>>, TError,{id: number;data: IUVideoDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateVideosHook>>>, TError,{id: number;data: IUVideoDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateVideos =  usePartialUpdateVideosHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateVideosHook>>>, {id: number;data: IUVideoDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateVideos(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateVideosMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateVideosHook>>>>
    export type PartialUpdateVideosMutationBody = IUVideoDTO
    export type PartialUpdateVideosMutationError = unknown

    /**
 * @summary Cập nhật videos
 */
export const usePartialUpdateVideos = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateVideosHook>>>, TError,{id: number;data: IUVideoDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateVideosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin người dùng
 */
export const useGetUsersHook = () => {
        const getUsers = useCustomInstance<UsersDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getUsers(
          {url: `/admincms/api/users/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetUsersQueryKey = (id: number,) => {
    
    return [`/admincms/api/users/${id}`] as const;
    }

    
export const useGetUsersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey(id);

  const getUsers =  useGetUsersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>> = ({ signal }) => getUsers(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>>
export type GetUsersQueryError = unknown

/**
 * @summary Lấy thông tin người dùng
 */
export const useGetUsers = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetUsersQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật thông tin người dùng
 */
export const usePartialUpdateUsersHook = () => {
        const partialUpdateUsers = useCustomInstance<void>();

        return (
    id: number,
    updateUserDTO: UpdateUserDTO,
 ) => {
        return partialUpdateUsers(
          {url: `/admincms/api/users/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUserDTO
    },
          );
        }
      }
    


export const usePartialUpdateUsersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateUsersHook>>>, TError,{id: number;data: UpdateUserDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateUsersHook>>>, TError,{id: number;data: UpdateUserDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateUsers =  usePartialUpdateUsersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateUsersHook>>>, {id: number;data: UpdateUserDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateUsers(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateUsersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateUsersHook>>>>
    export type PartialUpdateUsersMutationBody = UpdateUserDTO
    export type PartialUpdateUsersMutationError = unknown

    /**
 * @summary Cập nhật thông tin người dùng
 */
export const usePartialUpdateUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateUsersHook>>>, TError,{id: number;data: UpdateUserDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Lấy thông tin nhóm quyền
 * @summary Lấy thông tin nhóm quyền
 */
export const useGetRolesHook = () => {
        const getRoles = useCustomInstance<RoleGroupDetail>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getRoles(
          {url: `/admincms/api/role-groups/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetRolesQueryKey = (id: number,) => {
    
    return [`/admincms/api/role-groups/${id}`] as const;
    }

    
export const useGetRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRolesQueryKey(id);

  const getRoles =  useGetRolesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>> = ({ signal }) => getRoles(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>>
export type GetRolesQueryError = unknown

/**
 * @summary Lấy thông tin nhóm quyền
 */
export const useGetRoles = <TData = Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetRolesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Xóa nhóm quyền
 * @summary Xóa nhóm quyền
 */
export const useDeleteRolesHook = () => {
        const deleteRoles = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteRoles(
          {url: `/admincms/api/role-groups/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteRolesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteRolesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteRolesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteRoles =  useDeleteRolesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteRolesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteRoles(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteRolesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteRolesHook>>>>
    
    export type DeleteRolesMutationError = unknown

    /**
 * @summary Xóa nhóm quyền
 */
export const useDeleteRoles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteRolesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Cập nhật nhóm quyền
 * @summary Cập nhật nhóm quyền
 */
export const usePartialUpdateRolesHook = () => {
        const partialUpdateRoles = useCustomInstance<void>();

        return (
    id: number,
    iURoleGroupDTO: IURoleGroupDTO,
 ) => {
        return partialUpdateRoles(
          {url: `/admincms/api/role-groups/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: iURoleGroupDTO
    },
          );
        }
      }
    


export const usePartialUpdateRolesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateRolesHook>>>, TError,{id: number;data: IURoleGroupDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateRolesHook>>>, TError,{id: number;data: IURoleGroupDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateRoles =  usePartialUpdateRolesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateRolesHook>>>, {id: number;data: IURoleGroupDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateRoles(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateRolesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateRolesHook>>>>
    export type PartialUpdateRolesMutationBody = IURoleGroupDTO
    export type PartialUpdateRolesMutationError = unknown

    /**
 * @summary Cập nhật nhóm quyền
 */
export const usePartialUpdateRoles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateRolesHook>>>, TError,{id: number;data: IURoleGroupDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin bài viết
 */
export const useGetPostsHook = () => {
        const getPosts = useCustomInstance<PostsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getPosts(
          {url: `/admincms/api/posts/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPostsQueryKey = (id: number,) => {
    
    return [`/admincms/api/posts/${id}`] as const;
    }

    
export const useGetPostsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPostsQueryKey(id);

  const getPosts =  useGetPostsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>> = ({ signal }) => getPosts(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPostsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>>
export type GetPostsQueryError = unknown

/**
 * @summary Lấy thông tin bài viết
 */
export const useGetPosts = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPostsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa bài viết
 */
export const useDeletePostsHook = () => {
        const deletePosts = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deletePosts(
          {url: `/admincms/api/posts/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeletePostsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deletePosts =  useDeletePostsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePostsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deletePosts(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePostsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePostsHook>>>>
    
    export type DeletePostsMutationError = unknown

    /**
 * @summary Xóa bài viết
 */
export const useDeletePosts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeletePostsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật bài viết
 */
export const useUpdatePostsHook = () => {
        const updatePosts = useCustomInstance<void>();

        return (
    id: number,
    iUPostDTO: IUPostDTO,
 ) => {const formData = customFormData(iUPostDTO)
        return updatePosts(
          {url: `/admincms/api/posts/${id}`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUpdatePostsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostsHook>>>, TError,{id: number;data: IUPostDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostsHook>>>, TError,{id: number;data: IUPostDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updatePosts =  useUpdatePostsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdatePostsHook>>>, {id: number;data: IUPostDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updatePosts(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdatePostsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdatePostsHook>>>>
    export type UpdatePostsMutationBody = IUPostDTO
    export type UpdatePostsMutationError = unknown

    /**
 * @summary Cập nhật bài viết
 */
export const useUpdatePosts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostsHook>>>, TError,{id: number;data: IUPostDTO}, TContext>, }
) => {

      const mutationOptions = useUpdatePostsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin bình luận
 */
export const useGetPostCommentsHook = () => {
        const getPostComments = useCustomInstance<PostCommentsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getPostComments(
          {url: `/admincms/api/post-comments/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPostCommentsQueryKey = (id: number,) => {
    
    return [`/admincms/api/post-comments/${id}`] as const;
    }

    
export const useGetPostCommentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPostCommentsQueryKey(id);

  const getPostComments =  useGetPostCommentsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>> = ({ signal }) => getPostComments(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPostCommentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>>
export type GetPostCommentsQueryError = unknown

/**
 * @summary Lấy thông tin bình luận
 */
export const useGetPostComments = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCommentsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPostCommentsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa bình luận
 */
export const useDeletePostCommentsHook = () => {
        const deletePostComments = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deletePostComments(
          {url: `/admincms/api/post-comments/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeletePostCommentsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCommentsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCommentsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deletePostComments =  useDeletePostCommentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePostCommentsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deletePostComments(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePostCommentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePostCommentsHook>>>>
    
    export type DeletePostCommentsMutationError = unknown

    /**
 * @summary Xóa bình luận
 */
export const useDeletePostComments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCommentsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeletePostCommentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Duyệt bình luận
 */
export const usePartialUpdatePostCommentsHook = () => {
        const partialUpdatePostComments = useCustomInstance<PostCommentsDTO>();

        return (
    id: number,
    postCommentsDTO: PostCommentsDTO,
 ) => {
        return partialUpdatePostComments(
          {url: `/admincms/api/post-comments/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: postCommentsDTO
    },
          );
        }
      }
    


export const usePartialUpdatePostCommentsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePostCommentsHook>>>, TError,{id: number;data: PostCommentsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePostCommentsHook>>>, TError,{id: number;data: PostCommentsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdatePostComments =  usePartialUpdatePostCommentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePostCommentsHook>>>, {id: number;data: PostCommentsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdatePostComments(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdatePostCommentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePostCommentsHook>>>>
    export type PartialUpdatePostCommentsMutationBody = PostCommentsDTO
    export type PartialUpdatePostCommentsMutationError = unknown

    /**
 * @summary Duyệt bình luận
 */
export const usePartialUpdatePostComments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdatePostCommentsHook>>>, TError,{id: number;data: PostCommentsDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdatePostCommentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin danh mục bài viết
 */
export const useGetPostCategoriesHook = () => {
        const getPostCategories = useCustomInstance<PostCategoriesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getPostCategories(
          {url: `/admincms/api/post-categories/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPostCategoriesQueryKey = (id: number,) => {
    
    return [`/admincms/api/post-categories/${id}`] as const;
    }

    
export const useGetPostCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPostCategoriesQueryKey(id);

  const getPostCategories =  useGetPostCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>> = ({ signal }) => getPostCategories(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPostCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>>
export type GetPostCategoriesQueryError = unknown

/**
 * @summary Lấy thông tin danh mục bài viết
 */
export const useGetPostCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPostCategoriesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa danh mục bài viết
 */
export const useDeletePostCategoriesHook = () => {
        const deletePostCategories = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deletePostCategories(
          {url: `/admincms/api/post-categories/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeletePostCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCategoriesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCategoriesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deletePostCategories =  useDeletePostCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePostCategoriesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deletePostCategories(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePostCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePostCategoriesHook>>>>
    
    export type DeletePostCategoriesMutationError = unknown

    /**
 * @summary Xóa danh mục bài viết
 */
export const useDeletePostCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePostCategoriesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeletePostCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật danh mục bài viết
 */
export const useUpdatePostCategoriesHook = () => {
        const updatePostCategories = useCustomInstance<void>();

        return (
    id: number,
    iUPostCategoriesDTO: IUPostCategoriesDTO,
 ) => {
        return updatePostCategories(
          {url: `/admincms/api/post-categories/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: iUPostCategoriesDTO
    },
          );
        }
      }
    


export const useUpdatePostCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostCategoriesHook>>>, TError,{id: number;data: IUPostCategoriesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostCategoriesHook>>>, TError,{id: number;data: IUPostCategoriesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updatePostCategories =  useUpdatePostCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdatePostCategoriesHook>>>, {id: number;data: IUPostCategoriesDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  updatePostCategories(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdatePostCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdatePostCategoriesHook>>>>
    export type UpdatePostCategoriesMutationBody = IUPostCategoriesDTO
    export type UpdatePostCategoriesMutationError = unknown

    /**
 * @summary Cập nhật danh mục bài viết
 */
export const useUpdatePostCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePostCategoriesHook>>>, TError,{id: number;data: IUPostCategoriesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdatePostCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin giao diện
 */
export const useGetLayoutsHook = () => {
        const getLayouts = useCustomInstance<LayoutsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getLayouts(
          {url: `/admincms/api/layouts/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetLayoutsQueryKey = (id: number,) => {
    
    return [`/admincms/api/layouts/${id}`] as const;
    }

    
export const useGetLayoutsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLayoutsQueryKey(id);

  const getLayouts =  useGetLayoutsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>> = ({ signal }) => getLayouts(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLayoutsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>>
export type GetLayoutsQueryError = unknown

/**
 * @summary Lấy thông tin giao diện
 */
export const useGetLayouts = <TData = Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLayoutsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetLayoutsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cập nhật giao diện
 */
export const usePartialUpdateLayoutsHook = () => {
        const partialUpdateLayouts = useCustomInstance<LayoutsDTO>();

        return (
    id: number,
    layoutsDTO: LayoutsDTO,
 ) => {
        return partialUpdateLayouts(
          {url: `/admincms/api/layouts/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: layoutsDTO
    },
          );
        }
      }
    


export const usePartialUpdateLayoutsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLayoutsHook>>>, TError,{id: number;data: LayoutsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLayoutsHook>>>, TError,{id: number;data: LayoutsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateLayouts =  usePartialUpdateLayoutsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLayoutsHook>>>, {id: number;data: LayoutsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateLayouts(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateLayoutsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLayoutsHook>>>>
    export type PartialUpdateLayoutsMutationBody = LayoutsDTO
    export type PartialUpdateLayoutsMutationError = unknown

    /**
 * @summary Cập nhật giao diện
 */
export const usePartialUpdateLayouts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateLayoutsHook>>>, TError,{id: number;data: LayoutsDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateLayoutsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin câu hỏi thường gặp
 */
export const useGetFaqsHook = () => {
        const getFaqs = useCustomInstance<FaqsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getFaqs(
          {url: `/admincms/api/faqs/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetFaqsQueryKey = (id: number,) => {
    
    return [`/admincms/api/faqs/${id}`] as const;
    }

    
export const useGetFaqsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFaqsQueryKey(id);

  const getFaqs =  useGetFaqsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>> = ({ signal }) => getFaqs(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFaqsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>>
export type GetFaqsQueryError = unknown

/**
 * @summary Lấy thông tin câu hỏi thường gặp
 */
export const useGetFaqs = <TData = Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetFaqsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa câu hỏi thường gặp
 */
export const useDeleteFaqsHook = () => {
        const deleteFaqs = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteFaqs(
          {url: `/admincms/api/faqs/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteFaqsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteFaqs =  useDeleteFaqsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteFaqsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteFaqs(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFaqsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteFaqsHook>>>>
    
    export type DeleteFaqsMutationError = unknown

    /**
 * @summary Xóa câu hỏi thường gặp
 */
export const useDeleteFaqs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteFaqsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật câu hỏi thường gặp
 */
export const usePartialUpdateFaqsHook = () => {
        const partialUpdateFaqs = useCustomInstance<FaqsDTO>();

        return (
    id: number,
    faqsPatchDTO: FaqsPatchDTO,
 ) => {
        return partialUpdateFaqs(
          {url: `/admincms/api/faqs/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: faqsPatchDTO
    },
          );
        }
      }
    


export const usePartialUpdateFaqsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateFaqsHook>>>, TError,{id: number;data: FaqsPatchDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateFaqsHook>>>, TError,{id: number;data: FaqsPatchDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateFaqs =  usePartialUpdateFaqsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateFaqsHook>>>, {id: number;data: FaqsPatchDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateFaqs(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateFaqsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateFaqsHook>>>>
    export type PartialUpdateFaqsMutationBody = FaqsPatchDTO
    export type PartialUpdateFaqsMutationError = unknown

    /**
 * @summary Cập nhật câu hỏi thường gặp
 */
export const usePartialUpdateFaqs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateFaqsHook>>>, TError,{id: number;data: FaqsPatchDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateFaqsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin câu hỏi thường gặp
 */
export const useGetFaqCategoriesHook = () => {
        const getFaqCategories = useCustomInstance<FaqCategoriesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getFaqCategories(
          {url: `/admincms/api/faq-categories/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetFaqCategoriesQueryKey = (id: number,) => {
    
    return [`/admincms/api/faq-categories/${id}`] as const;
    }

    
export const useGetFaqCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFaqCategoriesQueryKey(id);

  const getFaqCategories =  useGetFaqCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>> = ({ signal }) => getFaqCategories(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFaqCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>>
export type GetFaqCategoriesQueryError = unknown

/**
 * @summary Lấy thông tin câu hỏi thường gặp
 */
export const useGetFaqCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFaqCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetFaqCategoriesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa câu hỏi thường gặp
 */
export const useDeleteFaqCategoriesHook = () => {
        const deleteFaqCategories = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteFaqCategories(
          {url: `/admincms/api/faq-categories/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteFaqCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqCategoriesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqCategoriesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteFaqCategories =  useDeleteFaqCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteFaqCategoriesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteFaqCategories(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFaqCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteFaqCategoriesHook>>>>
    
    export type DeleteFaqCategoriesMutationError = unknown

    /**
 * @summary Xóa câu hỏi thường gặp
 */
export const useDeleteFaqCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFaqCategoriesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteFaqCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật danh mục câu hỏi thường gặp
 */
export const usePatchFaqCategoriesHook = () => {
        const patchFaqCategories = useCustomInstance<FaqCategoriesDTO>();

        return (
    id: number,
    faqCategoriesPatchDTO: FaqCategoriesPatchDTO,
 ) => {
        return patchFaqCategories(
          {url: `/admincms/api/faq-categories/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: faqCategoriesPatchDTO
    },
          );
        }
      }
    


export const usePatchFaqCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchFaqCategoriesHook>>>, TError,{id: number;data: FaqCategoriesPatchDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchFaqCategoriesHook>>>, TError,{id: number;data: FaqCategoriesPatchDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchFaqCategories =  usePatchFaqCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchFaqCategoriesHook>>>, {id: number;data: FaqCategoriesPatchDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFaqCategories(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchFaqCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchFaqCategoriesHook>>>>
    export type PatchFaqCategoriesMutationBody = FaqCategoriesPatchDTO
    export type PatchFaqCategoriesMutationError = unknown

    /**
 * @summary Cập nhật danh mục câu hỏi thường gặp
 */
export const usePatchFaqCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchFaqCategoriesHook>>>, TError,{id: number;data: FaqCategoriesPatchDTO}, TContext>, }
) => {

      const mutationOptions = usePatchFaqCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy chi tiết thông tin liên hệ
 */
export const useGetContactsHook = () => {
        const getContacts = useCustomInstance<ContactsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getContacts(
          {url: `/admincms/api/contacts/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetContactsQueryKey = (id: number,) => {
    
    return [`/admincms/api/contacts/${id}`] as const;
    }

    
export const useGetContactsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContactsQueryKey(id);

  const getContacts =  useGetContactsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>> = ({ signal }) => getContacts(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetContactsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>>
export type GetContactsQueryError = unknown

/**
 * @summary Lấy chi tiết thông tin liên hệ
 */
export const useGetContacts = <TData = Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetContactsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa thông tin liên hệ
 */
export const useDeleteContactsHook = () => {
        const deleteContacts = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteContacts(
          {url: `/admincms/api/contacts/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteContactsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContactsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContactsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteContacts =  useDeleteContactsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteContactsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteContacts(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteContactsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteContactsHook>>>>
    
    export type DeleteContactsMutationError = unknown

    /**
 * @summary Xóa thông tin liên hệ
 */
export const useDeleteContacts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContactsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteContactsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin liên hệ
 */
export const usePartialUpdateContactsHook = () => {
        const partialUpdateContacts = useCustomInstance<ContactsDTO>();

        return (
    id: number,
    contactsDTO: ContactsDTO,
 ) => {
        return partialUpdateContacts(
          {url: `/admincms/api/contacts/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: contactsDTO
    },
          );
        }
      }
    


export const usePartialUpdateContactsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateContactsHook>>>, TError,{id: number;data: ContactsDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateContactsHook>>>, TError,{id: number;data: ContactsDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateContacts =  usePartialUpdateContactsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateContactsHook>>>, {id: number;data: ContactsDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateContacts(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateContactsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateContactsHook>>>>
    export type PartialUpdateContactsMutationBody = ContactsDTO
    export type PartialUpdateContactsMutationError = unknown

    /**
 * @summary Cập nhật thông tin liên hệ
 */
export const usePartialUpdateContacts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateContactsHook>>>, TError,{id: number;data: ContactsDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateContactsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Lấy thông tin biểu đồ
 * @summary Lấy thông tin biểu đồ
 */
export const useGetChartsHook = () => {
        const getCharts = useCustomInstance<ChartsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getCharts(
          {url: `/admincms/api/charts/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetChartsQueryKey = (id: number,) => {
    
    return [`/admincms/api/charts/${id}`] as const;
    }

    
export const useGetChartsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChartsQueryKey(id);

  const getCharts =  useGetChartsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>> = ({ signal }) => getCharts(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChartsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>>
export type GetChartsQueryError = unknown

/**
 * @summary Lấy thông tin biểu đồ
 */
export const useGetCharts = <TData = Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChartsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetChartsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Xóa biểu đồ
 * @summary Xóa biểu đồ
 */
export const useDeleteChartsHook = () => {
        const deleteCharts = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteCharts(
          {url: `/admincms/api/charts/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteChartsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteCharts =  useDeleteChartsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteChartsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteCharts(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteChartsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteChartsHook>>>>
    
    export type DeleteChartsMutationError = unknown

    /**
 * @summary Xóa biểu đồ
 */
export const useDeleteCharts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteChartsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteChartsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Cập nhật thông tin của biểu đồ
 * @summary Cập nhật thông tin của biểu đồ
 */
export const usePartialUpdateChartsHook = () => {
        const partialUpdateCharts = useCustomInstance<void>();

        return (
    id: number,
    iUChartDTO: IUChartDTO,
 ) => {
        return partialUpdateCharts(
          {url: `/admincms/api/charts/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: iUChartDTO
    },
          );
        }
      }
    


export const usePartialUpdateChartsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartsHook>>>, TError,{id: number;data: IUChartDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartsHook>>>, TError,{id: number;data: IUChartDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateCharts =  usePartialUpdateChartsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartsHook>>>, {id: number;data: IUChartDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateCharts(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateChartsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartsHook>>>>
    export type PartialUpdateChartsMutationBody = IUChartDTO
    export type PartialUpdateChartsMutationError = unknown

    /**
 * @summary Cập nhật thông tin của biểu đồ
 */
export const usePartialUpdateCharts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateChartsHook>>>, TError,{id: number;data: IUChartDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateChartsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin chi tiết banner
 */
export const useGetBannersHook = () => {
        const getBanners = useCustomInstance<BannerDetails>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getBanners(
          {url: `/admincms/api/banners/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetBannersQueryKey = (id: number,) => {
    
    return [`/admincms/api/banners/${id}`] as const;
    }

    
export const useGetBannersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBannersQueryKey(id);

  const getBanners =  useGetBannersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>> = ({ signal }) => getBanners(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBannersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>>
export type GetBannersQueryError = unknown

/**
 * @summary Lấy thông tin chi tiết banner
 */
export const useGetBanners = <TData = Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBannersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetBannersQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa banner
 */
export const useDeleteBannersHook = () => {
        const deleteBanners = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteBanners(
          {url: `/admincms/api/banners/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteBannersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteBanners =  useDeleteBannersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteBannersHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteBanners(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteBannersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteBannersHook>>>>
    
    export type DeleteBannersMutationError = unknown

    /**
 * @summary Xóa banner
 */
export const useDeleteBanners = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteBannersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin chung banner
 */
export const usePartialUpdateBannersHook = () => {
        const partialUpdateBanners = useCustomInstance<void>();

        return (
    id: number,
    updateBannerDTO: UpdateBannerDTO,
 ) => {
        return partialUpdateBanners(
          {url: `/admincms/api/banners/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateBannerDTO
    },
          );
        }
      }
    


export const usePartialUpdateBannersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannersHook>>>, TError,{id: number;data: UpdateBannerDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannersHook>>>, TError,{id: number;data: UpdateBannerDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateBanners =  usePartialUpdateBannersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannersHook>>>, {id: number;data: UpdateBannerDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateBanners(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateBannersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannersHook>>>>
    export type PartialUpdateBannersMutationBody = UpdateBannerDTO
    export type PartialUpdateBannersMutationError = unknown

    /**
 * @summary Cập nhật thông tin chung banner
 */
export const usePartialUpdateBanners = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannersHook>>>, TError,{id: number;data: UpdateBannerDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateBannersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xóa ảnh banner
 */
export const useDeleteBannersImageHook = () => {
        const deleteBannersImage = useCustomInstance<void>();

        return (
    id: number,
    sortOrder: number,
 ) => {
        return deleteBannersImage(
          {url: `/admincms/api/banners/${id}/images/${sortOrder}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteBannersImageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersImageHook>>>, TError,{id: number;sortOrder: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersImageHook>>>, TError,{id: number;sortOrder: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteBannersImage =  useDeleteBannersImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteBannersImageHook>>>, {id: number;sortOrder: number}> = (props) => {
          const {id,sortOrder} = props ?? {};

          return  deleteBannersImage(id,sortOrder,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteBannersImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteBannersImageHook>>>>
    
    export type DeleteBannersImageMutationError = unknown

    /**
 * @summary Xóa ảnh banner
 */
export const useDeleteBannersImage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteBannersImageHook>>>, TError,{id: number;sortOrder: number}, TContext>, }
) => {

      const mutationOptions = useDeleteBannersImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật thông tin ảnh banner
 */
export const usePartialUpdateBannerImagesHook = () => {
        const partialUpdateBannerImages = useCustomInstance<void>();

        return (
    id: number,
    sortOrder: number,
    updateBannerImageDTO: UpdateBannerImageDTO,
 ) => {const formData = customFormData(updateBannerImageDTO)
        return partialUpdateBannerImages(
          {url: `/admincms/api/banners/${id}/images/${sortOrder}`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const usePartialUpdateBannerImagesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannerImagesHook>>>, TError,{id: number;sortOrder: number;data: UpdateBannerImageDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannerImagesHook>>>, TError,{id: number;sortOrder: number;data: UpdateBannerImageDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateBannerImages =  usePartialUpdateBannerImagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannerImagesHook>>>, {id: number;sortOrder: number;data: UpdateBannerImageDTO}> = (props) => {
          const {id,sortOrder,data} = props ?? {};

          return  partialUpdateBannerImages(id,sortOrder,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateBannerImagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannerImagesHook>>>>
    export type PartialUpdateBannerImagesMutationBody = UpdateBannerImageDTO
    export type PartialUpdateBannerImagesMutationError = unknown

    /**
 * @summary Cập nhật thông tin ảnh banner
 */
export const usePartialUpdateBannerImages = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateBannerImagesHook>>>, TError,{id: number;sortOrder: number;data: UpdateBannerImageDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateBannerImagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy chi tiết album
 */
export const useGetAlbumsHook = () => {
        const getAlbums = useCustomInstance<AlbumsDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getAlbums(
          {url: `/admincms/api/albums/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAlbumsQueryKey = (id: number,) => {
    
    return [`/admincms/api/albums/${id}`] as const;
    }

    
export const useGetAlbumsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAlbumsQueryKey(id);

  const getAlbums =  useGetAlbumsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>> = ({ signal }) => getAlbums(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlbumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>>
export type GetAlbumsQueryError = unknown

/**
 * @summary Lấy chi tiết album
 */
export const useGetAlbums = <TData = Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAlbumsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAlbumsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa album
 */
export const useDeleteAlbumsHook = () => {
        const deleteAlbums = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteAlbums(
          {url: `/admincms/api/albums/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteAlbumsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAlbumsHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAlbumsHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteAlbums =  useDeleteAlbumsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteAlbumsHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteAlbums(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAlbumsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAlbumsHook>>>>
    
    export type DeleteAlbumsMutationError = unknown

    /**
 * @summary Xóa album
 */
export const useDeleteAlbums = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAlbumsHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteAlbumsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật album
 */
export const usePartialUpdateAlbumsHook = () => {
        const partialUpdateAlbums = useCustomInstance<void>();

        return (
    id: number,
    iUAlbumDTO: IUAlbumDTO,
 ) => {
        return partialUpdateAlbums(
          {url: `/admincms/api/albums/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: iUAlbumDTO
    },
          );
        }
      }
    


export const usePartialUpdateAlbumsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateAlbumsHook>>>, TError,{id: number;data: IUAlbumDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateAlbumsHook>>>, TError,{id: number;data: IUAlbumDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const partialUpdateAlbums =  usePartialUpdateAlbumsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialUpdateAlbumsHook>>>, {id: number;data: IUAlbumDTO}> = (props) => {
          const {id,data} = props ?? {};

          return  partialUpdateAlbums(id,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PartialUpdateAlbumsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialUpdateAlbumsHook>>>>
    export type PartialUpdateAlbumsMutationBody = IUAlbumDTO
    export type PartialUpdateAlbumsMutationError = unknown

    /**
 * @summary Cập nhật album
 */
export const usePartialUpdateAlbums = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialUpdateAlbumsHook>>>, TError,{id: number;data: IUAlbumDTO}, TContext>, }
) => {

      const mutationOptions = usePartialUpdateAlbumsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy chi tiết ảnh trong album
 */
export const useGetImageHook = () => {
        const getImage = useCustomInstance<AlbumImageDetail>();

        return (
    id: number,
    imageId: number,
 signal?: AbortSignal
) => {
        return getImage(
          {url: `/admincms/api/albums/${id}/images/${imageId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetImageQueryKey = (id: number,
    imageId: number,) => {
    
    return [`/admincms/api/albums/${id}/images/${imageId}`] as const;
    }

    
export const useGetImageQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>, TError = unknown>(id: number,
    imageId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetImageQueryKey(id,imageId);

  const getImage =  useGetImageHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>> = ({ signal }) => getImage(id,imageId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && imageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetImageQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>>
export type GetImageQueryError = unknown

/**
 * @summary Lấy chi tiết ảnh trong album
 */
export const useGetImage = <TData = Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>, TError = unknown>(
 id: number,
    imageId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetImageHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetImageQueryOptions(id,imageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Xóa ảnh trong album
 */
export const useDeleteImageHook = () => {
        const deleteImage = useCustomInstance<void>();

        return (
    id: number,
    imageId: number,
 ) => {
        return deleteImage(
          {url: `/admincms/api/albums/${id}/images/${imageId}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteImageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteImageHook>>>, TError,{id: number;imageId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteImageHook>>>, TError,{id: number;imageId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteImage =  useDeleteImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteImageHook>>>, {id: number;imageId: number}> = (props) => {
          const {id,imageId} = props ?? {};

          return  deleteImage(id,imageId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteImageHook>>>>
    
    export type DeleteImageMutationError = unknown

    /**
 * @summary Xóa ảnh trong album
 */
export const useDeleteImage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteImageHook>>>, TError,{id: number;imageId: number}, TContext>, }
) => {

      const mutationOptions = useDeleteImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cập nhật ảnh trong album
 */
export const useUpdateImageHook = () => {
        const updateImage = useCustomInstance<void>();

        return (
    id: number,
    imageId: number,
    iUAlbumImagesDTO: IUAlbumImagesDTO,
 ) => {const formData = customFormData(iUAlbumImagesDTO)
        return updateImage(
          {url: `/admincms/api/albums/${id}/images/${imageId}`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }
      }
    


export const useUpdateImageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateImageHook>>>, TError,{id: number;imageId: number;data: IUAlbumImagesDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateImageHook>>>, TError,{id: number;imageId: number;data: IUAlbumImagesDTO}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const updateImage =  useUpdateImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateImageHook>>>, {id: number;imageId: number;data: IUAlbumImagesDTO}> = (props) => {
          const {id,imageId,data} = props ?? {};

          return  updateImage(id,imageId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateImageHook>>>>
    export type UpdateImageMutationBody = IUAlbumImagesDTO
    export type UpdateImageMutationError = unknown

    /**
 * @summary Cập nhật ảnh trong album
 */
export const useUpdateImage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateImageHook>>>, TError,{id: number;imageId: number;data: IUAlbumImagesDTO}, TContext>, }
) => {

      const mutationOptions = useUpdateImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy danh sách người dùng
 */
export const useGetAllUsersHook = () => {
        const getAllUsers = useCustomInstance<PageUserSummary>();

        return (
    params?: GetAllUsersParams,
 signal?: AbortSignal
) => {
        return getAllUsers(
          {url: `/admincms/api/users`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams,) => {
    
    return [`/admincms/api/users`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllUsersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>, TError = unknown>(params?: GetAllUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

  const getAllUsers =  useGetAllUsersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>> = ({ signal }) => getAllUsers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>>
export type GetAllUsersQueryError = unknown

/**
 * @summary Lấy danh sách người dùng
 */
export const useGetAllUsers = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>, TError = unknown>(
 params?: GetAllUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllUsersHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy quyền của người dùng hiện tại
 */
export const useGetCurrentUserPermissionHook = () => {
        const getCurrentUserPermission = useCustomInstance<UserAuthorities>();

        return (
    
 signal?: AbortSignal
) => {
        return getCurrentUserPermission(
          {url: `/admincms/api/users/permissions`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetCurrentUserPermissionQueryKey = () => {
    
    return [`/admincms/api/users/permissions`] as const;
    }

    
export const useGetCurrentUserPermissionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentUserPermissionQueryKey();

  const getCurrentUserPermission =  useGetCurrentUserPermissionHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>> = ({ signal }) => getCurrentUserPermission(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentUserPermissionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>>
export type GetCurrentUserPermissionQueryError = unknown

/**
 * @summary Lấy quyền của người dùng hiện tại
 */
export const useGetCurrentUserPermission = <TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserPermissionHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetCurrentUserPermissionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách cấu hình
 */
export const useGetAllSettingsHook = () => {
        const getAllSettings = useCustomInstance<SettingDTO[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getAllSettings(
          {url: `/admincms/api/settings`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAllSettingsQueryKey = () => {
    
    return [`/admincms/api/settings`] as const;
    }

    
export const useGetAllSettingsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllSettingsQueryKey();

  const getAllSettings =  useGetAllSettingsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>> = ({ signal }) => getAllSettings(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllSettingsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>>
export type GetAllSettingsQueryError = unknown

/**
 * @summary Lấy danh sách cấu hình
 */
export const useGetAllSettings = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSettingsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy chi tiết cấu hình theo từ khóa
 */
export const useGetSettingHook = () => {
        const getSetting = useCustomInstance<GetSetting200>();

        return (
    key: 'GENERAL' | 'EMAIL' | 'SEO' | 'SERVER',
 signal?: AbortSignal
) => {
        return getSetting(
          {url: `/admincms/api/settings/${key}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetSettingQueryKey = (key: 'GENERAL' | 'EMAIL' | 'SEO' | 'SERVER',) => {
    
    return [`/admincms/api/settings/${key}`] as const;
    }

    
export const useGetSettingQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>, TError = unknown>(key: 'GENERAL' | 'EMAIL' | 'SEO' | 'SERVER', options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSettingQueryKey(key);

  const getSetting =  useGetSettingHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>> = ({ signal }) => getSetting(key, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSettingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>>
export type GetSettingQueryError = unknown

/**
 * @summary Lấy chi tiết cấu hình theo từ khóa
 */
export const useGetSetting = <TData = Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>, TError = unknown>(
 key: 'GENERAL' | 'EMAIL' | 'SEO' | 'SERVER', options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSettingHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSettingQueryOptions(key,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy file
 */
export const useGetFileResponseHook = () => {
        const getFileResponse = useCustomInstance<GetFileResponse200>();

        return (
    params: GetFileResponseParams,
 signal?: AbortSignal
) => {
        return getFileResponse(
          {url: `/admincms/api/seo-values/file`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetFileResponseQueryKey = (params: GetFileResponseParams,) => {
    
    return [`/admincms/api/seo-values/file`, ...(params ? [params]: [])] as const;
    }

    
export const useGetFileResponseQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>, TError = unknown>(params: GetFileResponseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFileResponseQueryKey(params);

  const getFileResponse =  useGetFileResponseHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>> = ({ signal }) => getFileResponse(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFileResponseQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>>
export type GetFileResponseQueryError = unknown

/**
 * @summary Lấy file
 */
export const useGetFileResponse = <TData = Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>, TError = unknown>(
 params: GetFileResponseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFileResponseHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetFileResponseQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách chức năng và thao tác
 */
export const useGetModulesAndActionsHook = () => {
        const getModulesAndActions = useCustomInstance<ModuleAndActionDTO[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getModulesAndActions(
          {url: `/admincms/api/role-groups/modules-and-actions`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetModulesAndActionsQueryKey = () => {
    
    return [`/admincms/api/role-groups/modules-and-actions`] as const;
    }

    
export const useGetModulesAndActionsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetModulesAndActionsQueryKey();

  const getModulesAndActions =  useGetModulesAndActionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>> = ({ signal }) => getModulesAndActions(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetModulesAndActionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>>
export type GetModulesAndActionsQueryError = unknown

/**
 * @summary Lấy danh sách chức năng và thao tác
 */
export const useGetModulesAndActions = <TData = Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetModulesAndActionsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetModulesAndActionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách bình luận
 */
export const useGetAllPostCommentsHook = () => {
        const getAllPostComments = useCustomInstance<PagePostCommentsDTO>();

        return (
    params?: GetAllPostCommentsParams,
 signal?: AbortSignal
) => {
        return getAllPostComments(
          {url: `/admincms/api/post-comments`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllPostCommentsQueryKey = (params?: GetAllPostCommentsParams,) => {
    
    return [`/admincms/api/post-comments`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllPostCommentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>, TError = unknown>(params?: GetAllPostCommentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPostCommentsQueryKey(params);

  const getAllPostComments =  useGetAllPostCommentsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>> = ({ signal }) => getAllPostComments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPostCommentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>>
export type GetAllPostCommentsQueryError = unknown

/**
 * @summary Lấy danh sách bình luận
 */
export const useGetAllPostComments = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>, TError = unknown>(
 params?: GetAllPostCommentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPostCommentsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllPostCommentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách Module
 */
export const useGetAllModuleHook = () => {
        const getAllModule = useCustomInstance<ModuleAndActionDTO[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getAllModule(
          {url: `/admincms/api/modules`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAllModuleQueryKey = () => {
    
    return [`/admincms/api/modules`] as const;
    }

    
export const useGetAllModuleQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllModuleQueryKey();

  const getAllModule =  useGetAllModuleHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>> = ({ signal }) => getAllModule(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllModuleQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>>
export type GetAllModuleQueryError = unknown

/**
 * @summary Lấy danh sách Module
 */
export const useGetAllModule = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllModuleHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllModuleQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách loại link
 */
export const useGetAllLinkTypeHook = () => {
        const getAllLinkType = useCustomInstance<string[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getAllLinkType(
          {url: `/admincms/api/link-types`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAllLinkTypeQueryKey = () => {
    
    return [`/admincms/api/link-types`] as const;
    }

    
export const useGetAllLinkTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllLinkTypeQueryKey();

  const getAllLinkType =  useGetAllLinkTypeHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>> = ({ signal }) => getAllLinkType(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllLinkTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>>
export type GetAllLinkTypeQueryError = unknown

/**
 * @summary Lấy danh sách loại link
 */
export const useGetAllLinkType = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLinkTypeHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllLinkTypeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách giao diện
 */
export const useGetAllLayoutsHook = () => {
        const getAllLayouts = useCustomInstance<PageLayoutsDTO>();

        return (
    params?: GetAllLayoutsParams,
 signal?: AbortSignal
) => {
        return getAllLayouts(
          {url: `/admincms/api/layouts`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllLayoutsQueryKey = (params?: GetAllLayoutsParams,) => {
    
    return [`/admincms/api/layouts`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllLayoutsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>, TError = unknown>(params?: GetAllLayoutsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllLayoutsQueryKey(params);

  const getAllLayouts =  useGetAllLayoutsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>> = ({ signal }) => getAllLayouts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllLayoutsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>>
export type GetAllLayoutsQueryError = unknown

/**
 * @summary Lấy danh sách giao diện
 */
export const useGetAllLayouts = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>, TError = unknown>(
 params?: GetAllLayoutsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLayoutsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllLayoutsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách ngôn ngữ và code
 */
export const useGetAllSystemLanguagesHook = () => {
        const getAllSystemLanguages = useCustomInstance<LanguageModel[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getAllSystemLanguages(
          {url: `/admincms/api/languages/list`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAllSystemLanguagesQueryKey = () => {
    
    return [`/admincms/api/languages/list`] as const;
    }

    
export const useGetAllSystemLanguagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllSystemLanguagesQueryKey();

  const getAllSystemLanguages =  useGetAllSystemLanguagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>> = ({ signal }) => getAllSystemLanguages(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllSystemLanguagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>>
export type GetAllSystemLanguagesQueryError = unknown

/**
 * @summary Lấy danh sách ngôn ngữ và code
 */
export const useGetAllSystemLanguages = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSystemLanguagesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllSystemLanguagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy danh sách câu hỏi thường gặp
 */
export const useGetAllFaqsHook = () => {
        const getAllFaqs = useCustomInstance<PageFaqsDTO>();

        return (
    params?: GetAllFaqsParams,
 signal?: AbortSignal
) => {
        return getAllFaqs(
          {url: `/admincms/api/faqs`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllFaqsQueryKey = (params?: GetAllFaqsParams,) => {
    
    return [`/admincms/api/faqs`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllFaqsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>, TError = unknown>(params?: GetAllFaqsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllFaqsQueryKey(params);

  const getAllFaqs =  useGetAllFaqsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>> = ({ signal }) => getAllFaqs(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllFaqsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>>
export type GetAllFaqsQueryError = unknown

/**
 * @summary Lấy danh sách câu hỏi thường gặp
 */
export const useGetAllFaqs = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>, TError = unknown>(
 params?: GetAllFaqsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllFaqsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllFaqsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Chi tiết danh mục văn bản
 */
export const useGetDocumentCategoriesHook = () => {
        const getDocumentCategories = useCustomInstance<DocumentCategoriesDTO>();

        return (
    id: number,
 signal?: AbortSignal
) => {
        return getDocumentCategories(
          {url: `/admincms/api/document-categories/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetDocumentCategoriesQueryKey = (id: number,) => {
    
    return [`/admincms/api/document-categories/${id}`] as const;
    }

    
export const useGetDocumentCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentCategoriesQueryKey(id);

  const getDocumentCategories =  useGetDocumentCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>> = ({ signal }) => getDocumentCategories(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>>
export type GetDocumentCategoriesQueryError = unknown

/**
 * @summary Chi tiết danh mục văn bản
 */
export const useGetDocumentCategories = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentCategoriesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentCategoriesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const useDeleteDocumentCategoriesHook = () => {
        const deleteDocumentCategories = useCustomInstance<void>();

        return (
    id: number,
 ) => {
        return deleteDocumentCategories(
          {url: `/admincms/api/document-categories/${id}`, method: 'delete'
    },
          );
        }
      }
    


export const useDeleteDocumentCategoriesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentCategoriesHook>>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentCategoriesHook>>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentCategories =  useDeleteDocumentCategoriesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentCategoriesHook>>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocumentCategories(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentCategoriesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentCategoriesHook>>>>
    
    export type DeleteDocumentCategoriesMutationError = unknown

    export const useDeleteDocumentCategories = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentCategoriesHook>>>, TError,{id: number}, TContext>, }
) => {

      const mutationOptions = useDeleteDocumentCategoriesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lấy thông tin liên hệ
 */
export const useGetAllContactsHook = () => {
        const getAllContacts = useCustomInstance<PageContactsDTO>();

        return (
    params?: GetAllContactsParams,
 signal?: AbortSignal
) => {
        return getAllContacts(
          {url: `/admincms/api/contacts`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetAllContactsQueryKey = (params?: GetAllContactsParams,) => {
    
    return [`/admincms/api/contacts`, ...(params ? [params]: [])] as const;
    }

    
export const useGetAllContactsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>, TError = unknown>(params?: GetAllContactsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllContactsQueryKey(params);

  const getAllContacts =  useGetAllContactsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>> = ({ signal }) => getAllContacts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllContactsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>>
export type GetAllContactsQueryError = unknown

/**
 * @summary Lấy thông tin liên hệ
 */
export const useGetAllContacts = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>, TError = unknown>(
 params?: GetAllContactsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllContactsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllContactsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary lấy ra danh sách thay đổi của các đối tượng trong hệ thống
 */
export const useGetChangesHook = () => {
        const getChanges = useCustomInstance<PageAuditLogDTO>();

        return (
    params?: GetChangesParams,
 signal?: AbortSignal
) => {
        return getChanges(
          {url: `/admincms/api/audits/entity/changes`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetChangesQueryKey = (params?: GetChangesParams,) => {
    
    return [`/admincms/api/audits/entity/changes`, ...(params ? [params]: [])] as const;
    }

    
export const useGetChangesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>, TError = unknown>(params?: GetChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChangesQueryKey(params);

  const getChanges =  useGetChangesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>> = ({ signal }) => getChanges(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChangesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>>
export type GetChangesQueryError = unknown

/**
 * @summary lấy ra danh sách thay đổi của các đối tượng trong hệ thống
 */
export const useGetChanges = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>, TError = unknown>(
 params?: GetChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetChangesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy ra các phiên bản thay đổi của đối tượng
 */
export const useGetPrevVersionHook = () => {
        const getPrevVersion = useCustomInstance<AuditLogDTO>();

        return (
    params?: GetPrevVersionParams,
 signal?: AbortSignal
) => {
        return getPrevVersion(
          {url: `/admincms/api/audits/entity/changes/version/previous`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetPrevVersionQueryKey = (params?: GetPrevVersionParams,) => {
    
    return [`/admincms/api/audits/entity/changes/version/previous`, ...(params ? [params]: [])] as const;
    }

    
export const useGetPrevVersionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>, TError = unknown>(params?: GetPrevVersionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPrevVersionQueryKey(params);

  const getPrevVersion =  useGetPrevVersionHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>> = ({ signal }) => getPrevVersion(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPrevVersionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>>
export type GetPrevVersionQueryError = unknown

/**
 * @summary Lấy ra các phiên bản thay đổi của đối tượng
 */
export const useGetPrevVersion = <TData = Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>, TError = unknown>(
 params?: GetPrevVersionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPrevVersionHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPrevVersionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Lấy ra tất cả đối tượng trong hệ thống
 */
export const useGetAuditedEntitiesHook = () => {
        const getAuditedEntities = useCustomInstance<AuditEntity[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getAuditedEntities(
          {url: `/admincms/api/audits/entity/all`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAuditedEntitiesQueryKey = () => {
    
    return [`/admincms/api/audits/entity/all`] as const;
    }

    
export const useGetAuditedEntitiesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuditedEntitiesQueryKey();

  const getAuditedEntities =  useGetAuditedEntitiesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>> = ({ signal }) => getAuditedEntities(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAuditedEntitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>>
export type GetAuditedEntitiesQueryError = unknown

/**
 * @summary Lấy ra tất cả đối tượng trong hệ thống
 */
export const useGetAuditedEntities = <TData = Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAuditedEntitiesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAuditedEntitiesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


