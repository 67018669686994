/* eslint-disable react-hooks/rules-of-hooks */
import { useGetCurrentUserPermissionHook } from "@api/admincms";
import { useLogoutHook, useMeHook } from "@api/auth";
import store from "store";

export async function currentAccount() {
  if (!isAuthenticated()) {
    return false;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMeHook()()
    .then((response) => {
      if (response) {
        store.set(
          "app.role",
          response?.roles?.map((item) => item.name.split("ROLE_")[0]) || []
        );
        return response;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
export async function currentPermission() {
  if (!isAuthenticated()) {
    return false;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetCurrentUserPermissionHook()()
    .then((response) => {
      if (response) {
        store.set("app.permission", response);
        return response;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export async function logout() {
  return useLogoutHook()({
    uuid: store.get("uuid"),
  })
    .then(() => {
      store.clearAll();
      return true;
    })
    .catch((err) => console.log(err));
}

export function isAuthenticated() {
  return store.get("accessToken") !== undefined;
}
