import { lazy, Suspense, useLayoutEffect, useState } from "react";
import { Route, Navigate, Routes, Router } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useSelector } from "react-redux";

import Layout from "layouts";
import { selectSettings } from "store/settingSlice";
import System404 from "pages/error/404";

const routes = [
  // personal-user
  // {
  //   path: '/personal-user',
  //   Component: lazy(() => import('pages/personal-user')),
  //   exact: true,
  // },
  // home
  {
    path: "home",
    Component: lazy(() => import("pages/dashboard")),
    exact: true,
  },

  // personal user
  {
    path: "personal-user/profile",
    Component: lazy(() => import("pages/personal-user/profile")),
    exact: true,
  },
  // notification
  {
    path: "personal-user/notifications",
    Component: lazy(() => import("pages/notifications")),
    exact: true,
  },
  // setting
  {
    path: "personal-user/setting-notifications",
    Component: lazy(() => import("pages/personal-user/notifications")),
    exact: true,
  },
  {
    path: "personal-user/authentication",
    Component: lazy(() => import("pages/personal-user/authentication")),
    exact: true,
  },
  {
    path: "personal-user/active-sessions",
    Component: lazy(() => import("pages/personal-user/sessions")),
    exact: true,
  },
  {
    path: "personal-user/change-pw",
    Component: lazy(() => import("pages/personal-user/change-password")),
    exact: true,
  },
  // {
  //   path: '/personal-user/profile',
  //   Component: lazy(() => import('pages/personal-user/profile')),
  //   exact: true,
  // },
  // {
  //   path: '/personal-user/change-pw',
  //   Component: lazy(() => import('pages/personal-user/change-password')),
  //   exact: true,
  // },

  // administration
  {
    path: "manage/doc-types",
    Component: lazy(() => import("pages/administration/doc-types")),
    exact: true,
  },
  {
    path: "manage/doc-groups",
    Component: lazy(() => import("pages/administration/doc-groups")),
    exact: true,
  },
  {
    path: "manage/agents",
    Component: lazy(() => import("pages/administration/agents")),
    exact: true,
  },
  {
    path: "manage/users",
    Component: lazy(() => import("pages/administration/accounts")),
    exact: true,
  },

  {
    path: "manage/files",
    Component: lazy(() => import("pages/administration/files")),
    exact: true,
  },
  {
    path: "manage/configurations",
    Component: lazy(() => import("pages/administration/configurations")),
    exact: true,
  },
  {
    path: "manage/e-ticket/systems",
    Component: lazy(() => import("pages/administration/e-tickets/systems")),
    exact: true,
  },
  {
    path: "manage/e-ticket/sub-systems",
    Component: lazy(() => import("pages/administration/e-tickets/sub-systems")),
    exact: true,
  },
  // manage ticket
  {
    path: "e-ticket",
    Component: lazy(() => import("pages/tickets")),
    exact: true,
  },
  {
    path: "e-ticket/:id/view",
    Component: lazy(() => import("pages/tickets/detail")),
    exact: true,
  },
  {
    path: "my-eticket",
    Component: lazy(() => import("pages/tickets")),
    exact: true,
  },
  {
    path: "my-eticket/:id/view",
    Component: lazy(() => import("pages/tickets/detail")),
    exact: true,
  },

  // statistic
  {
    path: "reports/receive",
    Component: lazy(() => import("pages/reports/statistic-receives")),
    exact: true,
  },

  {
    path: "reports/send",
    Component: lazy(() => import("pages/reports/statistic-sends")),
    exact: true,
  },
  {
    path: "reports/comment",
    Component: lazy(() => import("pages/reports/statistic-responses")),
    exact: true,
  },

  // publish document
  {
    path: "documents/publish",
    Component: lazy(() => import("pages/publish-document")),
    exact: true,
  },

  // publish document view detail
  {
    path: "documents/:id/viewp",
    Component: lazy(() => import("pages/publish-document/detail")),
    exact: true,
  },
  // document
  {
    path: "documents",
    Component: lazy(() => import("pages/document")),
    exact: true,
  },

  // document view detail
  {
    path: "documents/:id/view",
    Component: lazy(() => import("pages/document/detail")),
    exact: true,
  },
  // Auth Pages
  {
    path: "auth/login",
    Component: lazy(() => import("pages/auth/login")),
    exact: true,
  },
  {
    path: "auth/login/AD",
    Component: lazy(() => import("pages/auth/login/AD")),
    exact: true,
  },
  {
    path: "auth/forgot-password",
    Component: lazy(() => import("pages/auth/forgot-password")),
    exact: true,
  },
  {
    path: "auth/reset-password",
    Component: lazy(() => import("pages/auth/reset-password")),
    exact: true,
  },

  {
    path: "error/403",
    Component: lazy(() => import("pages/error/403")),
    exact: true,
  },
  {
    path: "error/404",
    Component: lazy(() => import("pages/error/404")),
    exact: true,
  },
  {
    path: "error/500",
    Component: lazy(() => import("pages/error/500")),
    exact: true,
  },

  // Admin CMS
  {
    path: "/cms",
    Component: lazy(() => import("admin-cms")),
    exact: true,
  },
  // Cms Quản lý danh mục tin tức
  {
    path: "/cms/management/post-category",
    Component: lazy(() =>
      import("admin-cms/pages/post-management/post-category")
    ),
    exact: true,
  },
  // Cms Quản lý tin tức/bài viết
  {
    path: "/cms/management/post",
    Component: lazy(() => import("admin-cms/pages/post-management/post")),
    exact: true,
  },
  // Cms Quản lý danh mục văn bản
  {
    path: "/cms/management/document-category",
    Component: lazy(() =>
      import("admin-cms/pages/document-management/document-category")
    ),
    exact: true,
  },
  // Cms Quản lý loại văn bản
  {
    path: "/cms/management/document-type",
    Component: lazy(() =>
      import("admin-cms/pages/document-management/document-type")
    ),
    exact: true,
  },

  // Cms Quản lý đơn vị phát hành
  {
    path: "/cms/management/document-publisher",
    Component: lazy(() =>
      import("admin-cms/pages/document-management/document-publisher")
    ),
    exact: true,
  },

  // Cms Quản lý văn bản
  {
    path: "/cms/management/document",
    Component: lazy(() =>
      import("admin-cms/pages/document-management/documents")
    ),
    exact: true,
  },

  // Cms Quản lý banner
  {
    path: "/cms/management/banner",
    Component: lazy(() => import("admin-cms/pages/banner-management")),
    exact: true,
  },
  // Cms Quản lý bình luận
  {
    path: "/cms/management/comment",
    Component: lazy(() => import("admin-cms/pages/comment-management")),
    exact: true,
  },
  // Cms Quản lý chuyên mục hỏi đáp
  {
    path: "/cms/management/faq-category",
    Component: lazy(() =>
      import("admin-cms/pages/faq-management/faq-category")
    ),
    exact: true,
  },
  // Cms Quản lý hỏi đáp
  {
    path: "/cms/management/faq",
    Component: lazy(() => import("admin-cms/pages/faq-management/faq")),
    exact: true,
  },
  // Cms Quản lý Media
  // Cms Quản lý Video
  {
    path: "/cms/management/video",
    Component: lazy(() =>
      import("admin-cms/pages/media-management/video-management")
    ),
    exact: true,
  },
  // Cms Quản lý Album
  {
    path: "/cms/management/image-album",
    Component: lazy(() =>
      import("admin-cms/pages/media-management/album-management")
    ),
    exact: true,
  },

  // Cms Quản lý Album
  {
    path: "/cms/management/link",
    Component: lazy(() => import("admin-cms/pages/navigation-management")),
    exact: true,
  },

  // Cms Quản lý cấu hình
  {
    path: "/cms/management/setting/config",
    Component: lazy(() =>
      import("admin-cms/pages/setting-management/config-management")
    ),
    exact: true,
  },

  // Cms Quản lý Log
  {
    path: "/cms/management/setting/log",
    Component: lazy(() =>
      import("admin-cms/pages/setting-management/log-management")
    ),
    exact: true,
  },
  // Cms Quản lý Giao diện
  {
    path: "/cms/management/setting/interface",
    Component: lazy(() =>
      import("admin-cms/pages/setting-management/interface-management")
    ),
    exact: true,
  },

  // Cms Quản lý ngôn ngữ
  {
    path: "/cms/management/setting/language",
    Component: lazy(() =>
      import("admin-cms/pages/setting-management/language-management")
    ),
    exact: true,
  },
  // Cms quản lý trang
  {
    path: "/cms/management/page",
    Component: lazy(() => import("admin-cms/pages/page-management")),
    exact: true,
  },
  // Cms quản lý Người dùng - người dùng
  {
    path: "/cms/management/user",
    Component: lazy(() => import("admin-cms/pages/user-management/User")),
    exact: true,
  },
  // Cms quản lý Người dùng - nhóm quyền
  {
    path: "/cms/management/role",
    Component: lazy(() => import("admin-cms/pages/user-management/Role")),
    exact: true,
  },
  // Cms quản lý liên hệ
  {
    path: "/cms/management/contact",
    Component: lazy(() => import("admin-cms/pages/contact-management")),
    exact: true,
  },
  // Cms quản lý biểu đồ
  {
    path: "/cms/management/chart",
    Component: lazy(() => import("admin-cms/pages/chart-management")),
    exact: true,
  },
];

const AppRouter = ({ history, basename }) => {
  const { routerAnimation } = useSelector(selectSettings);
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  const renderRoute = ({ path, children, Component }) => {
    return (
      <Route
        path={path}
        key={path}
        element={
          <div className={routerAnimation}>
            <Suspense fallback={null}>
              <Component />
            </Suspense>
          </div>
        }
      >
        {children?.map((item) => renderRoute(item))}
      </Route>
    );
  };

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          {routes.map((item) => renderRoute(item))}
          <Route path="*" element={<System404 />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
